import Collection from "../../libs/collection";
import { queryTollers, removeToller } from "./service";

class Tollers extends Collection {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      filter: {
        condition: "",
        pageIndex: 1,
        pageCount: 10
      }
    };
  }

  queryTollers() {
    return queryTollers(this.state.filter).then(res => {
      this.list(res.data, res.pagination);
    });
  }

  searchTollers(values) {
    return this.filter({ pageIndex: 1, ...values }).then(() => {
      return this.queryTollers();
    });
  }

  resetTollerParamsAndFetch() {
    return this.filter({ pageIndex: 1, condition: "" }).then(() => {
      return this.queryTollers();
    });
  }

  removeToller(toller) {
    return removeToller(toller.id).then(() => this.remove(toller));
  }
}
export default new Tollers();
