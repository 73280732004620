import React, { Component } from 'react';
import moment from 'moment';
import { DatePicker } from 'antd';
const { RangePicker } = DatePicker;

export default class DateTimeRangePicker extends Component {
  constructor() {
    super();
    this.start = null;
    this.send = null;
  }

  componentWillReceiveProps(props) {
    // if (Array.isArray(props.value) && props.value[0] == null){
    //   this.start = null;
    //   this.end = null;
    // }
  }

  handleDateChange(start, end, dateStrings) {
    this.start = start;
    this.end = end;
    this.props.onChange && this.props.onChange([start, end])
  }

  disabledDate(current) {
    if (this.props.disabledDate) {
      return this.props.disabledDate(current)
    }
    return false;
  }

  render() {

    return (
      <RangePicker
        disabledDate={current => this.disabledDate(current)}
        showTime={
          !this.props.disableTime ? 
          {
            hideDisabledOptions: true,
            defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('00:00:00', 'HH:mm:ss')],
          } : false
        }
        format={this.props.format || "YYYY-MM-DD HH:mm:ss" }
        value={this.props.value}
        onChange={([start, end], dateStrings) => this.handleDateChange(start, end, dateStrings)}
        placeholder={['开始时间', '结束时间']}
        {
          ...(this.props.rest || {})
        }
      />
    )
  }
}
