import React, { Component } from "react";
import { Table, message, Button } from "antd";
import { Subscribe } from "unstated";
import model from "./model";

import moment from "moment";
import "./list.less";
import { TOLLER_SHIFT } from "../../constants";
import { Link } from "react-router-dom";
const { Column, ColumnGroup } = Table;
export default class ChargeReportsDetailList extends Component {
  onChange({ current, pageSize }) {
    model
      .filterChargeReportsDetail({
        pageIndex: current,
        pageCount: pageSize
      })
      .catch(e => message.error(e.message));
  }

  render() {
    // 交易收入 = amount + refound   实际收入 = amount + rfound - newRefound

    const totalFlow = d =>
      d.noSenseWechatPayCount +
      d.noSenseWechatRefundCount +
      d.scanAlipayPayCount +
      d.scanAlipayRefundCount +
      d.scanWechatPayCount +
      d.scanWechatRefundCount;

    const title = type => {
      return {
        batch: "车道",
        day: "班次",
        month: "日期",
        year: "日期"
      }[type];
    };

    const { stationId, date, type } = this.props.match.params;

    const field = (type, d) => {
      let s = "";
      switch (type) {
        case "batch":
          s = d.laneNo;
          break;
        case "day":
          s = TOLLER_SHIFT[d.classesType] || "合计";
          break;
        case "month":
          s = d.accountDate;
          break;
        case "year":
          s = d.accountDate;
          break;
        default:
          s = "合计";
      }

      return s;
    };

    const toFixed = n =>
      n === n && typeof n === "number" ? (n % 1 === 0 ? n : n.toFixed(2)) : "";
    const nwxIcome = d =>
      (d.noSenseWechatRefundAmount + d.noSenseWechatPayAmount) / 100;
    const nwxRefound = d => d.noSenseWechatNewRefundAmount / 100;
    const nwxOwned = d => nwxIcome(d) - nwxRefound(d);

    const swxIcome = d =>
      (d.scanWechatPayAmount + d.scanWechatRefundAmount) / 100;
    const swxRefound = d => d.scanWechatNewRefundAmount / 100;
    const swxOwned = d => swxIcome(d) - swxRefound(d);

    const salIcome = d =>
      (d.scanAlipayPayAmount + d.scanAlipayRefundAmount) / 100;
    const salRefound = d => d.scanAlipayNewRefundAmount / 100;
    const salOwned = d => salIcome(d) - salRefound(d);

    const totalIcome = d => nwxIcome(d) + swxIcome(d) + salIcome(d);
    const totalOwned = d => nwxOwned(d) + swxOwned(d) + salOwned(d);

    const monthYearTable = model => (
      <table className="ChargeReportDetail-Table">
        <thead>
          <tr>
            <th rowSpan="5">{title(type)}</th>
            <th colSpan="14">金额(元)</th>
            <th colSpan="5">流量(次)</th>
            {type === "month" ? (
              <>
                <th rowSpan="5">订单明细</th>
                <th rowSpan="5">备注</th>
              </>
            ) : (
              <th rowSpan="5">备注</th>
            )}
          </tr>
          <tr>
            <th rowSpan="3" colSpan="2">
              收入合计
            </th>
            <th colSpan="12">腾路智能支付</th>
            <th rowSpan="4">合计</th>
            <th colSpan="4">腾路智能支付</th>
          </tr>
          <tr>
            <th colSpan="3">无感支付</th>
            <th colSpan="9">扫码支付</th>
            <th>无感支付</th>
            <th colSpan="3">扫码支付</th>
          </tr>
          <tr>
            <th colSpan="3">微信支付</th>
            <th colSpan="3">小计</th>
            <th colSpan="3">微信支付</th>
            <th colSpan="3">支付宝</th>

            <th rowSpan="2">微信支付</th>
            <th rowSpan="2">小计</th>
            <th rowSpan="2">微信支付</th>
            <th rowSpan="2">支付宝</th>
          </tr>
          <tr>
            <th>交易收入</th>
            <th>实际收入</th>
            <th>交易收入</th>
            <th>实际收入</th>
            <th>退款</th>
            <th>交易收入</th>
            <th>实际收入</th>
            <th>退款</th>
            <th>交易收入</th>
            <th>实际收入</th>
            <th>退款</th>
            <th>交易收入</th>
            <th>实际收入</th>
            <th>退款</th>
          </tr>
        </thead>
        <tbody>
          {model.state.list.map((d, index) => (
            <tr>
              <td>{field(type, d)}</td>
              <td>{toFixed(totalIcome(d))}</td>
              <td>{toFixed(totalOwned(d))}</td>
              <td>{toFixed(nwxIcome(d))}</td>
              <td>{toFixed(nwxOwned(d))}</td>
              <td>{toFixed(nwxRefound(d))}</td>
              <td>{toFixed(swxIcome(d) + salIcome(d))}</td>
              <td>{toFixed(swxOwned(d) + salOwned(d))}</td>
              <td>{toFixed(swxRefound(d) + salRefound(d))}</td>
              <td>{toFixed(swxIcome(d))}</td>
              <td>{toFixed(swxOwned(d))}</td>
              <td>{toFixed(swxRefound(d))}</td>
              <td>{toFixed(salIcome(d))}</td>
              <td>{toFixed(salOwned(d))}</td>
              <td>{toFixed(salRefound(d))}</td>
              <td>{totalFlow(d)}</td>
              <td>{d.noSenseWechatPayCount + d.noSenseWechatRefundCount}</td>
              <td>
                {d.scanAlipayPayCount +
                  d.scanAlipayRefundCount +
                  d.scanWechatPayCount +
                  d.scanWechatRefundCount}
              </td>
              <td>{d.scanWechatPayCount + d.scanWechatRefundCount}</td>
              <td>{d.scanAlipayPayCount + d.scanAlipayRefundCount}</td>

              {type === "month" ? (
                <td className="ChargeReportDetail-MonthlyDetail">
                  {index < model.state.list.length - 1 ? (
                    <Link
                      to={`/order/monthlyFlow/${stationId}/${d.accountDate.substr(
                        0,
                        10
                      )}`}
                    >
                      查看明细
                    </Link>
                  ) : null}
                </td>
              ) : null}
              <td>{d.remark}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );

    const batchAndDayTable = model => (
      <table className="ChargeReportDetail-Table">
        <thead>
          <tr>
            <th rowSpan="5">{title(type)}</th>
            <th colSpan="5">金额(元)</th>
            <th colSpan="5">流量(次)</th>
          </tr>
          <tr>
            <th rowSpan="4">交易收入合计</th>
            <th colSpan="4">腾路智能支付</th>
            <th rowSpan="4">合计</th>
            <th colSpan="4">腾路智能支付</th>
          </tr>
          <tr>
            <th colSpan="1">无感支付</th>
            <th colSpan="3">扫码支付</th>
            <th>无感支付</th>
            <th colSpan="3">扫码支付</th>
          </tr>
          <tr>
            <th colSpan="1">微信支付</th>
            <th colSpan="1">小计</th>
            <th colSpan="1">微信支付</th>
            <th colSpan="1">支付宝</th>

            <th rowSpan="2">微信支付</th>
            <th rowSpan="2">小计</th>
            <th rowSpan="2">微信支付</th>
            <th rowSpan="2">支付宝</th>
          </tr>
          <tr>
            <th>交易收入</th>

            <th>交易收入</th>

            <th>交易收入</th>

            <th>交易收入</th>
          </tr>
        </thead>
        <tbody>
          {model.state.list.map((d, index) => (
            <tr>
              <td>{field(type, d)}</td>
              <td>{toFixed(totalIcome(d))}</td>
              <td>{toFixed(nwxIcome(d))}</td>

              <td>{toFixed(swxIcome(d) + salIcome(d))}</td>

              <td>{toFixed(swxIcome(d))}</td>

              <td>{toFixed(salIcome(d))}</td>

              <td>{toFixed(totalFlow(d))}</td>
              <td>{d.noSenseWechatPayCount + d.noSenseWechatRefundCount}</td>
              <td>
                {d.scanAlipayPayCount +
                  d.scanAlipayRefundCount +
                  d.scanWechatPayCount +
                  d.scanWechatRefundCount}
              </td>
              <td>{d.scanWechatPayCount + d.scanWechatRefundCount}</td>
              <td>{d.scanAlipayPayCount + d.scanAlipayRefundCount}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );

    return (
      <Subscribe to={[model]}>
        {["batch", "day"].includes(type) ? batchAndDayTable : monthYearTable}
      </Subscribe>
    );
  }
}
