import request from "../../libs/request";
import { getUserInfo } from "../../service";
export function commitRefundment(params) {
  return getUserInfo().then(user =>
    request("/data/trade/commitRefundApply", {
      ...params,
      merchant_id: user.merchant_id,
      refund_fee: +params.refund_fee * 100
    }).then(res => res.data)
  );
}
