import { addRefoundTicket, editRefoundTicket, getOrderDetail } from "./service";
import FormModel from "../../libs/formModel";
import RefoundTicketCollection from "../refoundTickets/model";
import schema from "./schema";
class RefoundTicketForm extends FormModel {
  writeSealBill(values) {
    return this.submit(values).then(() => {
      RefoundTicketCollection.resetRefoundTicketsQueryParamsAndFetch();
    });
  }

  getOrderDetail(orderNo, stationId) {
    return getOrderDetail(orderNo, stationId);
  }
}

export default new RefoundTicketForm({
  addAction: addRefoundTicket,
  editAction: editRefoundTicket,
  schema
});
