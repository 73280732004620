import { Container } from 'unstated';
import { getOrderDetail , getCarPic } from './service';
class OrderDetail extends Container {
  constructor(props) {
    super(props);
    this.initialState = {
      submitting: false,
      fields: {}
    }
    this.state = {
      ...this.initialState
    }
    
  }

  getDetail(form){
    return this.setState({
      fields: {...form}
    })
  }
    
  getOrderDetail(id){
    return getOrderDetail(id).then(this.getDetail.bind(this))
  }

  getCarPic(url){
    return getCarPic(url);
  }

  clearForm(){
    this.setState({
      ...this.initialState
    })
  };


}

export default new OrderDetail();