import React, { Component } from "react";
import { Menu, Dropdown, Icon, Spin, message } from "antd";
import { Link, withRouter } from "react-router-dom";
import styles from "./index.less";
import Auth from "../../auth";
import { logout } from "../../service";

class UserMenu extends Component {
  constructor() {
    super();
    this.state = {
      user: {}
    };
  }
  componentDidMount() {
    Auth.getUserInfo()
      .then(user => {
        this.setState({ user });
      })
      .catch(e => message.error("获取用户信息失败"));
  }

  render() {
    const menu = (
      <Menu className="userMenu-box">
        <Menu.Item>
          <Link
            to={{
              pathname: `/users/info`,
              state: { modal: true, from: this.props.location }
            }}
          >
            个人信息
          </Link>
        </Menu.Item>
        {!!this.state.user.user_type ? (
          <Menu.Item>
            <Link
              to={{
                pathname: `/users/repassword`,
                state: { modal: true, from: this.props.location }
              }}
            >
              修改密码
            </Link>
          </Menu.Item>
        ) : null}

        <Menu.Item>
          <a
            onClick={() =>{
                logout();
                Auth.signout(() => this.props.history.push("/login"))
              }
            }
          >
            退出
          </a>
        </Menu.Item>
      </Menu>
    );
    return (
      <Dropdown overlay={menu}>
        <div className="UserMenu">
          <Icon
            type="user"
            style={{
              border: "1px solid",
              borderRadius: "50%",
              marginRight: "5px"
            }}
          />
          <span>{this.state.user.nick_name || "获取用户失败"}</span>
        </div>
      </Dropdown>
    );
  }
}

export default withRouter(UserMenu);
