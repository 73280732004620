import React, { Component } from "react";
import { Card, Button, Radio } from "antd";
import { Link } from "react-router-dom";
import Search from "./search";
import List from "./list";
import { Provider } from "unstated";
import "./index.less";
export default class OrderRecords extends Component {
  componentDidMount() {}
  render() {
    const titles = {
      "/orders/records": "支付订单",
      "/orders/refundment": "退款订单"
    };

    return (
      <Provider>
        <div className="page-content OrderRecords">
          <Card className="header">
            <div className="title">{titles[this.props.location.pathname]}</div>
          </Card>

          <Card className="content">
            <Search />
            <List scrollX={true} location={this.props.location} />
          </Card>
        </div>
      </Provider>
    );
  }
}
