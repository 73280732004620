import request from "../../libs/request";
import { getUserInfo } from "../../service";
import uuid from "../../libs/uuid";
export function fetchChargeReportsDetailOfBatch(params) {
  return getUserInfo()
    .then(user =>
      request("/data/stat/getTollShiftDetail", {
        stationId: params.stationId,
        accountDate: params.date,
        classesType: params.id,
        merchantId: user.merchant_id
      })
    )
    .then(res => {
      let list = res.data.map((item, id) => ({ ...item }));
      list.length
        ? list.push(
            list.reduce((total, curr) => {
              let last = { ...total };
              for (let key in curr) {
                last[key] = (last[key] || 0) + (curr[key] || 0);
              }
              last.tollCollectorId = "";
              last.laneNo = "合计"; 
              last.remark = "";
              return last;
            }, {})
          )
        : null;
      return {
        ...res,
        data: [...list].map(item => ({ id: uuid(), ...item }))
      };
    });
}

export function fetchChargeReportsDetailOfDay(params) {
  return getUserInfo()
    .then(user =>
      request("/data/stat/getDailyReportDetail", {
        stationId: params.stationId,
        accountDate: params.date,
        merchantId: user.merchant_id
      })
    )
    .then(res => {
      let list = res.data.map((item, id) => ({ ...item }));
      list.length
        ? list.push(
            list.reduce((total, curr) => {
              let last = { ...total };
              for (let key in curr) {
                last[key] = (last[key] || 0) + (curr[key] || 0);
              }
              last.tollCollectorShilf = "";
              last.classesType = "合计";
              last.remark = "";
              return last;
            }, {})
          )
        : null;
      return {
        ...res,
        data: [...list].map(item => ({ id: uuid(), ...item }))
      };
    });
}

export function fetchChargeReportsDetailOfMonth(params) {
  return getUserInfo()
    .then(user =>
      request("/data/stat/getMonthlyReportDetail", {
        stationId: params.stationId,
        accountDate: params.date.substr(0, 8),
        merchantId: user.merchant_id
      })
    )
    .then(res => {
      let list = res.data.map((item, id) => ({ ...item }));
      list.length
        ? list.push(
            list.reduce((total, curr) => {
              let last = { ...total };
              for (let key in curr) {
                last[key] = (last[key] || 0) + (curr[key] || 0);
              }
              last.accountDate = "合计";
              last.remark = "";
              return last;
            }, {})
          )
        : null;
      return {
        ...res,
        data: [...list].map(item => ({ id: uuid(), ...item }))
      };
    });
}

export function fetchChargeReportsDetailOfYear(params) {
  return getUserInfo()
    .then(user =>
      request("/data/stat/getYearReportDetail", {
        stationId: params.stationId,
        accountDate: params.date.substr(0, 8),
        merchantId: user.merchant_id
      })
    )
    .then(res => {
      let list = res.data.map((item, id) => ({ ...item }));
      list.length
        ? list.push(
            list.reduce((total, curr) => {
              let last = { ...total };
              for (let key in curr) {
                last[key] = (last[key] || 0) + (curr[key] || 0);
              }
              last.accountDate = "合计";
              last.remark = "";
              return last;
            }, {})
          )
        : null;
      return {
        ...res,
        data: [...list].map(item => ({ id: uuid(), ...item }))
      };
    });
}

export function postPrintLog(params) {
  return getUserInfo()
    .then(user =>
      request(
        "/data/stat/printReport",
        Object.assign(
          {
            stationId: params.stationId,
            merchantId: user.merchant_id,
            reportModule: { batch: 1, day: 2, month: 3 }[params.reportType],
            date: params.date.substr(0,10)
          },
          params.reportType === "batch" ? { shift: +params.tollerShift } : {}
        )
      )
    )
    .catch(console.log);
}
