import { addToller, editToller } from "./service";
import FormModel from "../../libs/formModel";
import TollerCollection from "../tollers/model";
import schema from "./schema";
class TollerForm extends FormModel {
  writeToller(values) {
    return this.submit(values).then(() => {
      TollerCollection.resetTollerParamsAndFetch();
    });
  }
}

export default new TollerForm({
  addAction: addToller,
  editAction: editToller,
  schema
});
