import React, { Component } from 'react';
import { Modal , message} from 'antd';
import { Provider, Subscribe } from 'unstated';
import model from './model.js';
import Form from './form.js';
import Orders from '../orderRecords/model';
export default class DrawBack extends Component {

  handleCancel(e) {
    const { match, history } = this.props;
    //e.stopPropagation();
    history.goBack();
  }

  onSubmit(values) {
    const { match, history } = this.props;

    let res = model.submit(values)
      .then(data => {
        Orders.queryPayedOrders().catch(e => message.error(e.message));
      })
      .then(message.success.bind(message,'发起退款成功'))
      .then(history.goBack)
      .catch(e => {
        console.log(e);
        message.error(e.message || '发起退款失败')
      })

  }

  componentDidMount() {

  }

  componentWillUnmount() {

    model.clearForm && model.clearForm();
  }
  render() {
    return (
      <Provider>
        <Subscribe to={[model]}>
          {model => (
            <Modal
              closable={model.state.submitting ? false : true}
              confirmLoading={model.state.submitting}
              maskClosable={false}
              title="发起退款"
              visible={true}
              onOk={this.onSubmit.bind(this)}
              onCancel={this.handleCancel.bind(this)}
              footer={null}
            >
              <Form model={model} onCancel={this.handleCancel.bind(this)} onSubmit={this.onSubmit.bind(this)} location={this.props.location}/>
            </Modal>)
          }
        </Subscribe>
      </Provider>
    )
  }
}