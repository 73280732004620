import React, { Component } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { Page } from "./components";
import Login from "./pages/login/login";
import app from "./app";
import auth from "./auth";
import {
  ALL_VISITOR,
  ONLY_ADMIN,
  VISITOR_HAS_REFOUND_PERMISSION,
  VISITOR_HAS_MISACC_PERMISSION,
  ONLY_REAL_ADMIN,
  VISITOR_HAS_CLASSES_PERMISSION
} from "./constants";
app.registPage("/orders/records", require("./pages/orderRecords").default);
app.registPage(
  "/orders/refundment",
  require("./pages/orderRefoundments").default
);
app.registPage("/orders/detail/:id", require("./pages/orderDetail").default);
app.registModal(
  "/orders/drawback/:id",
  require("./pages/drawback").default,
  VISITOR_HAS_REFOUND_PERMISSION
);
app.registModal("/orders/info/:id", require("./pages/orderInfo").default);

app.registPage("/balance/records", require("./pages/balanceRecords").default);
app.registPage("/balance/errors", require("./pages/balanceErrors").default);
app.registPage(
  "/balance/dayrecords",
  require("./pages/balanceDayRecodes").default
);
app.registModal(
  "/balance/handleError",
  require("./pages/balanceErrorHandling").default,
  VISITOR_HAS_MISACC_PERMISSION
);
app.registModal(
  "/balance/handleResult",
  require("./pages/balanceErrorHandleResult").default
);

app.registModal(
  "/users/repassword",
  require("./pages/password").default,
  ALL_VISITOR
);
app.registModal(
  "/users/info",
  require("./pages/userInfo").default,
  ALL_VISITOR
);
app.registModal(
  "/users/add",
  require("./pages/userForm").default,
  ONLY_REAL_ADMIN
);
app.registModal(
  "/users/edit/:id",
  require("./pages/userForm").default,
  ONLY_REAL_ADMIN
);
app.registPage(
  "/users/records",
  require("./pages/users").default,
  ONLY_REAL_ADMIN
);

app.registPage(
  "/tollers/records",
  require("./pages/tollers").default,
  ONLY_REAL_ADMIN
);
app.registModal(
  "/tollers/add",
  require("./pages/tollerForm").default,
  ONLY_REAL_ADMIN
);
app.registModal(
  "/tollers/edit/:id",
  require("./pages/tollerForm").default,
  ONLY_REAL_ADMIN
);

// app.registPage("/seals/records", require("./pages/sealsRecords").default);
// app.registModal(
//   "/seals/add",
//   require("./pages/sealsForm").default,
//   VISITOR_HAS_CLASSES_PERMISSION
// );
// app.registModal(
//   "/seals/edit/:id",
//   require("./pages/sealsForm").default,
//   VISITOR_HAS_CLASSES_PERMISSION
// );

app.registPage(
  "/refoundTickets/records",
  require("./pages/refoundTickets").default
);
app.registModal(
  "/refoundTickets/add",
  require("./pages/refoundTicketForm").default,
  VISITOR_HAS_CLASSES_PERMISSION
);
app.registModal(
  "/refoundTickets/edit/:id",
  require("./pages/refoundTicketForm").default,
  VISITOR_HAS_CLASSES_PERMISSION
);

app.registPage(
  "/chargeReports/records/:type",
  require("./pages/chargeReports").default
);
app.registPage(
  "/chargeReports/detail/:type/:stationId/:date/:id",
  require("./pages/chargeReportDetail").default
);
app.registPage(
  "/logs/login",
  require("./pages/loginLogs").default,
  ONLY_REAL_ADMIN
);
app.registPage(
  "/logs/operation",
  require("./pages/operationLogs").default,
  ONLY_REAL_ADMIN
);
app.registPage(
  "/balance/monthly",
  require("./pages/balanceMonthly").default,
);

app.registPage(
  "/order/monthlyFlow/:stationId/:date",
  require("./pages/orderRecordsWithFlow").default,
);

const page_urls = Object.keys(app.pages);
const modal_urls = Object.keys(app.modals);

const PageRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      auth.canRoute(rest.path) ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: props.location }
          }}
        />
      )
    }
  />
);

class ModalSwitch extends React.Component {
  constructor(props) {
    super(props);
    this.previousLocation = this.props.location;
  }

  componentWillUpdate(nextProps) {
    const { location } = this.props;
    // set previousLocation if props.location is not modal
    if (
      nextProps.history.action !== "POP" &&
      (!location.state || !location.state.modal)
    ) {
      this.previousLocation = this.props.location;
    }
  }

  render() {
    const { location } = this.props;
    const isModal = !!(location.state && location.state.modal); // not initial render

    const previousLocation = isModal ? location.state.from : null;

    return (
      <div>
        {page_urls.map(
          url =>
            isModal ? (
              <PageRoute
                key={url}
                path={url}
                component={app.getPage(url).component}
              />
            ) : null
        )}
        <Switch location={isModal ? previousLocation : location}>
          {page_urls.map(url => (
            <PageRoute
              key={url}
              path={url}
              component={app.getPage(url).component}
            />
          ))}
        </Switch>
        {modal_urls.map(
          url =>
            isModal ? (
              <PageRoute
                key={url}
                path={url}
                component={app.getModal(url).component}
              />
            ) : null
        )}
      </div>
    );
  }
}

export default class Router extends Component {
  render() {
    return (
      <Switch>
        <Route
          exact
          path="/"
          render={() =>
            auth.isAuthenticated ? (
              <Redirect to="/orders/records" />
            ) : (
              <Redirect to="/login" />
            )
          }
        />
        <Route path="/login" excat component={Login} />
        <Page>
          <Route component={ModalSwitch} />
        </Page>
      </Switch>
    );
  }
}
