import Collection from "../../libs/collection";
import {
  fetchChargeReportsOfBatch,
  fetchChargeReportsOfDay,
  fetchChargeReportsOfMonth,
  fetchChargeReportsOfYear,
  exportChargeReportsOfBatch,
  exportChargeReportsOfDay,
  exportChargeReportsOfMonth,
  exportChargeReportsOfYear
} from "./service";
import moment from "moment";
class ChargeReports extends Collection {
  constructor(props) {
    super(props);
    this.initialFilter = {
      pageIndex: 1,
      pageCount: 10
    };
    this.state = {
      ...this.state,
      filter: { ...this.initialFilter },
      reportType: "batch"
    };
  }

  fetchChargeReports(reportType) {
    
    let fetchFn = {
      batch: fetchChargeReportsOfBatch,
      day: fetchChargeReportsOfDay,
      month: fetchChargeReportsOfMonth,
      year: fetchChargeReportsOfYear
    }[reportType || this.state.reportType];

    return fetchFn(this.state.filter).then(res => {
      this.list(res.data, res.pagination);
    });
  }

  queryChargeReports(query,reportType) {
    let [startDate, endDate] = query.date || [];
    delete query.date;
    let params = {
      ...query,
      startDate: startDate
        ? moment(startDate).format("YYYY-MM-DD")
        : this.state.startDate,
      endDate: endDate
        ? moment(endDate).format("YYYY-MM-DD")
        : this.state.endDate
    };
    return this.filter({
      ...params,
      pageIndex: 1
    }).then(() => this.fetchChargeReports(reportType));
  }

  filterChargeReports(params) {
    return this.filter({
      ...params
    }).then(() => this.fetchChargeReports(params.reportType));
  }

  resetChargeReportsQueryParamsAndFetch(type) {
    this.setState({
      reportType: type ? type : this.state.reportType
    });
    return this.filter({
      ...this.initialFilter
    }).then(() => this.fetchChargeReports(type ? type : this.state.reportType));
  }

  exportChargeReports(params, type) {
    let exportFn = {
      batch: exportChargeReportsOfBatch,
      day: exportChargeReportsOfDay,
      month: exportChargeReportsOfMonth,
      year: exportChargeReportsOfYear
    }[type];
    return exportFn({
      stationId: this.state.filter.stationId,
      pageIndex: -1,
      ...params
    });
  }
}
export default new ChargeReports();
