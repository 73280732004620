import request from "../../libs/request";
import { getUserInfo } from "../../service";
export function addRefoundTicket(params) {
  return getUserInfo().then(user =>
    request("/data/stat/createRefund", {
      ...params,
      merchantId: user.merchant_id
    }).then(({ id }) => ({
      ...params,
      id
    }))
  );
}
export function editRefoundTicket(params) {
  return getUserInfo().then(user =>
    request("/data/stat/modifyRefund", {
      ...params,
      merchantId: user.merchant_id
    })
  );
}
export function getOrderDetail(orderNo, stationId) {
  return getUserInfo().then(user =>
    request("/data/stat/searchOrder", {
      orderNo,
      stationId,
      merchantId: user.merchant_id
    }).then(res => res.data)
  );
}
