import React, { Component } from 'react';
import { Modal , message } from 'antd';
import { Provider, Subscribe } from 'unstated';
import model from './model.js';
import Form from './form.js';
export default class UserForm extends Component {

  handleCancel(e) {
    const { match, history } = this.props;
    history.goBack();
  }

  onSubmit(values) {
    const { match: { params: id }, history } = this.props;

    let res = model.submit(values)
      .then(() => message.success('密码修改成功'))
      .then(history.goBack)
      .catch(e => {
        console.log(e);
        message.error(e.message || '密码修改失败')
      })

  }

  componentDidMount() {

  }

  componentWillUnmount() {

    model.clearForm && model.clearForm();
  }
  render() {
    const { match: { params: id } } = this.props

    return (
      <Provider>
        <Subscribe to={[model]}>
          {model => (
            <Modal
              closable={model.state.submitting ? false : true}
              confirmLoading={model.state.submitting}
              maskClosable={false}
              title={"修改密码"}
              visible={true}
              onOk={this.onSubmit.bind(this)}
              onCancel={this.handleCancel.bind(this)}
              footer={null}
            >
              <Form model={model} onCancel={this.handleCancel.bind(this)} onSubmit={this.onSubmit.bind(this)} />
            </Modal>)
          }
        </Subscribe>
      </Provider>
    )
  }
}