import React, { Component } from "react";
import { Table, message, Divider, Modal } from "antd";
import { Subscribe } from "unstated";
import model from "./model";
import { Link } from "react-router-dom";
const { Column, ColumnGroup } = Table;
import { CAR_FEE_TYPE, TOLLER_SHIFT } from "../../constants";
import auth from "../../auth";
export default class RefoundTicketsList extends Component {
  onChange({ current, pageSize }) {
    model
      .filterRefoundTickets({
        pageIndex: current,
        pageCount: pageSize
      })
      .catch(e => message.error(e.message));
  }

  onRemoveRefoundTicket(id) {
    Modal.confirm({
      title: "提示",
      content: "是否确定删除退款票？",
      onOk() {
        model
          .removeRefoundTicket(id)
          .then(() => message.success("删除成功"))
          .catch(e => {
            console.log(e);
            message.error("删除失败");
          });
      },
      onCancel() {}
    });
  }

  render() {
    const { scrollX } = this.props;
    return (
      <Subscribe to={[model]}>
        {model => (
          <Table
            pagination={{
              ...model.state.pagination,
              showSizeChanger: true,
              showQuickJumper: true,
              showTotal: total => `共${total}条数据`
            }}
            onChange={this.onChange.bind(this)}
            scroll={{ x: !!scrollX ? true : false }}
            rowKey="orderNo"
            className="list"
            dataSource={model.state.list}
          >
            <Column
              title="封账日期"
              dataIndex="sealBillDate"
              key="sealBillDate"
              render={v => (typeof v === "string" ? v.substr(0, 10) : "")}
            />
            {/* <Column
              title="收费站点"
              dataIndex="stationName"
              key="stationName"
            /> */}
            <Column
              title="收费员名称"
              dataIndex="tollCollectorName"
              key="tollCollectorName"
            />
            <Column
              title="收费员工号"
              dataIndex="tollCollectorNo"
              key="tollCollectorNo"
            />
            <Column title="收费班次" dataIndex="tollShift" key="tollShift" render={v => TOLLER_SHIFT[v]}/>
            <Column
              title="收费班组"
              dataIndex="tollCollectorGroup"
              key="tollCollectorGroup"
            />
            <Column
              title="车辆类型"
              dataIndex="vechileType"
              key="vechileType"
              render={v => CAR_FEE_TYPE[v]}
            />

            <Column title="车牌号" dataIndex="plateNo" key="plateNo" />
            <Column title="订单号" dataIndex="orderNo" key="orderNo" />
            <Column
              title="金额(元)"
              dataIndex="amount"
              key="amount"
              render={v => (
                <span className="money">{v ? (v / 100).toFixed(2) : 0}</span>
              )}
            />

            <Column title="监控员工号" dataIndex="monitorNo" key="monitorNo" />

            <Column
              title="状态"
              dataIndex="status"
              key="status"
              render={v => (v ? "已封账" : "未封账")}
            />

            {auth.canRoute(`/refoundTickets/edit/1`) ? (
              <Column
                title="操作"
                key="action"
                fixed="right"
                width="auto"
                render={item => (
                  <span>
                    <Link
                      disabled={item.status}
                      to={{
                        pathname: `/refoundTickets/edit/${item.id}`,
                        state: {
                          modal: true,
                          fields: { ...item },
                          from: this.props.location
                        }
                      }}
                    >
                      编辑
                    </Link>
                    <Divider type="vertical" />
                    <a
                      disabled={item.status}
                      onClick={() => this.onRemoveRefoundTicket(item)}
                    >
                      删除
                    </a>
                  </span>
                )}
              />
            ) : (
              <Column title=" " dataIndex="field_more" key="field_more" />
            )}
          </Table>
        )}
      </Subscribe>
    );
  }
}
