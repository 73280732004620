import request from '../../libs/request';
import exportFile from '../../libs/exportFile';

export function getBlanceRecodesDetailOfDay(params){
  return request('/data/trade/queryDetailOfCS',params)
}

export function exportBlanceRecodesDetailOfDay(params){
  return exportFile('/data/trade/exportDetailOfCS',params)
}
	