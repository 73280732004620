import Collection from '../../libs/collection';
import { getBalanceMonthly, exportBalanceMonthly } from './service'
import auth from '../../auth';
class BalanceMonthly extends Collection {

  getBalanceMonthly() {
    return getBalanceMonthly(this.state.filter)
      .then(res => {
        this.list(res.data, res.pagination)
      })
  }
  exportBalanceMonthly(params) {
    return exportBalanceMonthly({ ...this.state.filter , ...params});
  }
  hasExportAuthority(){
    return  auth.hasExportAuthority();
  }
}
export default new BalanceMonthly()