import React, { Component } from 'react';
import { Form, Button, Input, Select, Alert } from 'antd';
import { Provider, Subscribe, Container } from 'unstated';
import { BALANCE_ERROR_TYPE } from '../../constants';
const Field = Form.Item;
class PureForm extends Component {

  onSubmit(e) {
    const { onSubmit, form, location: { state: { fields } } } = this.props;
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        let data = {
          id: fields.id,
          handle_remark: values.handle_remark,
        };

        onSubmit && onSubmit(data)
      }
    });

  }

  onCancel() {
    this.props.onCancel && this.props.onCancel()
  }


  render() {
    const { model, form, location: { state: { fields } } } = this.props;
    const { getFieldDecorator, getFieldValue } = this.props.form;
    
    return (
      <Form className="modal-form" onSubmit={this.onSubmit.bind(this)}>

        <Field label="差错类型"><span className="text">{BALANCE_ERROR_TYPE[fields.mistakeType]}</span></Field>

        <Field label="备注" style={{marginBottom: '15px'}}>
          {getFieldDecorator('handle_remark', {
            rules: [{ required: false, message: '请输入备注' }, { max: 100, message: '长度不能超过100个字符' }]
          })(<Input.TextArea rows={2}/>)}
        </Field>

        <div className="modal-form-footer">
          <Button className="modal-form-cancel" onClick={this.onCancel.bind(this)} disabled={model.state.submitting}>取消</Button>
          <Button className="modal-form-submit" type="primary" htmlType="submit" loading={model.state.submitting}>确定</Button>
        </div>
      </Form>
    )
  }
}

const WrapedPureForm = Form.create()(PureForm);

export default WrapedPureForm;


