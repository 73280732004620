import React, { Component } from "react";
import { Form, Button, Input, message } from "antd";
const Field = Form.Item;
import model from "./model";
import { SiteSelector, DateTimeRangePicker } from "../../components";
import { Link } from "react-router-dom";
import auth from "../../auth";
class RefoundTicketsSearch extends Component {
  onSubmit(e) {
    const { form } = this.props;
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        model.queryRefoundTickets(values).catch(e => message.error(e.message));
      }
    });
  }

  onReset() {
    const { resetFields } = this.props.form;
    resetFields(["employeeNo", "date"]);

    model
      .resetRefoundTicketsQueryParamsAndFetch()
      .catch(e => message.error(e.message));
  }
  onStationChange(stationId) {
    model
      .queryRefoundTickets({ stationId })
      .catch(e => message.error(e.message));
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const date_options = {
      initialValue: [null, null]
    };

    return (
      <Form
        layout="inline"
        className="search"
        onSubmit={this.onSubmit.bind(this)}
      >
        <div style={{ marginBottom: "10px" }}>
          <Field label="收费站点">
            {getFieldDecorator("stationId")(
              <SiteSelector onChange={this.onStationChange.bind(this)} />
            )}
          </Field>
          {auth.canRoute(`/refoundTickets/add`) ? (
            <Button type="primary" className="add-user">
              <Link
                to={{
                  pathname: `/refoundTickets/add`,
                  state: { modal: true, from: this.props.location }
                }}
              >
                新增
              </Link>
            </Button>
          ) : null}
        </div>
        <Field label="封账日期">
          {getFieldDecorator("date", date_options)(
            <DateTimeRangePicker showTime={false} format="YYYY-MM-DD" />
          )}
        </Field>
        <Field label="关键词">
          {getFieldDecorator("employeeNo")(
            <Input placeholder="收费员工号/监控员工号" />
          )}
        </Field>
        <Button type="primary" htmlType="submit">
          查询
        </Button>
        <Button htmlType="button" onClick={() => this.onReset()}>
          重置
        </Button>
        {model.hasExportAuthority() ? (
          <Button
            className="export"
            onClick={() => model.exportRefoundTickets()}
          >
            导出数据
          </Button>
        ) : null}
      </Form>
    );
  }
}

export default Form.create()(RefoundTicketsSearch);
