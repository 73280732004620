import React, { Component } from "react";
import { Table, Divider, message } from "antd";
import { Link } from "react-router-dom";
const { Column, ColumnGroup } = Table;
import { Subscribe } from "unstated";
import model from "./model";
import { getUserInfo, getPaymentSetting } from "../../service";
import moment from "moment";
import {
  BALANCE_ACCOUNT_RESULT,
  BALANCE_CACHE_ERROR,
  BALANCE_ACCOUNT_ERROR,
  BALANCE_ACCOUNT_AND_CACHE_ERROR
} from "../../constants";
function getDay(date) {
  return (
    date +
    "(星期" +
    ["天", "一", "二", "三", "四", "五", "六"][moment(date).day()] +
    ")"
  );
}
export default class BalanceRecodeList extends Component {
  componentDidMount() {
    getUserInfo()
      .then(user => {
        model
          .filter({
            merchant_id: user.merchant_id + "",
            pageIndex: 1,
            pageCount: 10,
            startTime: moment()
              .subtract(7, "days")
              .startOf("day")
              .format("YYYY-MM-DD HH:mm:ss"),
            endTime: moment()
              .endOf("day")
              .format("YYYY-MM-DD HH:mm:ss")
          })
          .then(() => {
            model.getBalanceRecords().catch(e => message.error(e.message));
          });
      })
      .catch(e => {
        console.log(e);
        message.error("获取用户信息");
      });
  }
  onChange({ current, pageSize }, filters, sorter) {
    model
      .filter({
        pageIndex: current,
        pageCount: pageSize
      })
      .then(() => {
        model.getBalanceRecords().catch(e => message.error(e.message));
      });
  }
  render() {
    const { scrollX } = this.props;
    return (
      <Subscribe to={[model]}>
        {model => (
          <Table
            rowKey="id"
            pagination={{
              ...model.state.pagination,
              showSizeChanger: true,
              showQuickJumper: true,
              showTotal: total => `共${total}条数据`
            }}
            onChange={this.onChange.bind(this)}
            scroll={{ x: !!scrollX ? true : false }}
            className="list"
            dataSource={model.state.list}
            rowClassName={(record) => {
              if (
                record.recon_status === BALANCE_CACHE_ERROR ||
                record.recon_status === BALANCE_ACCOUNT_ERROR || 
                record.recon_status === BALANCE_ACCOUNT_AND_CACHE_ERROR
              ) {
                return "Balance-error";
              }
            }}
          >
            <Column
              title="交易日期"
              dataIndex="date"
              key="date"
              render={v => getDay(v)}
            />
            <Column
              title="商户名称"
              dataIndex="merchant_name"
              key="merchant_name"
            />
            <ColumnGroup title="总计">
              <Column
                title={<span>腾路总单数(笔)</span>}
                dataIndex="tengits_total_count"
                key="tengits_total_count"
                render={v => <span className="amount">{v}</span>}
                className="sub-column"
              />
              <Column
                title={<span>渠道总单数(笔)</span>}
                dataIndex="channel_total_count"
                key="channel_total_count"
                render={v => <span className="amount">{v}</span>}
                className="sub-column"
              />
              <Column
                title={<span>应收总金额(元)</span>}
                dataIndex="tengits_total_money"
                key="tengits_total_money"
                render={v => (
                  <span className="money">{v ? (v / 100).toFixed(2) : 0}</span>
                )}
                className="sub-column"
              />
              <Column
                title={<span>实收总金额(元)</span>}
                dataIndex="channel_total_money"
                key="channel_total_money"
                render={v => (
                  <span className="money">{v ? (v / 100).toFixed(2) : 0}</span>
                )}
                className="sub-column"
              />
              <Column
                title={<span>手续费(元)</span>}
                dataIndex="channel_total_fee"
                key="channel_total_fee"
                render={v => (
                  <span className="money">{v ? (v / 100).toFixed(2) : 0}</span>
                )}
                className="sub-column"
              />
              <Column
                title={<span>结算金额(元)</span>}
                dataIndex="channel_total_settlement_money"
                key="channel_total_settlement_money"
                render={v => (
                  <span className="money">{v ? (v / 100).toFixed(2) : 0}</span>
                )}
                className="sub-column"
              />
            </ColumnGroup>
            <ColumnGroup title="支付交易">
              <Column
                title={<span>腾路单数(笔)</span>}
                dataIndex="tengits_payed_count"
                key="tengits_payed_count"
                render={v => <span className="amount">{v}</span>}
                className="sub-column"
              />
              <Column
                title={<span>渠道单数(笔)</span>}
                dataIndex="channel_payed_count"
                key="channel_payed_count"
                render={v => <span className="amount">{v}</span>}
                className="sub-column"
              />
              <Column
                title={<span>应收金额(元)</span>}
                dataIndex="tengits_payed_money"
                key="tengits_payed_money"
                render={v => (
                  <span className="money">{v ? (v / 100).toFixed(2) : 0}</span>
                )}
                className="sub-column"
              />
              <Column
                title={<span>实收金额(元)</span>}
                dataIndex="channel_payed_money"
                key="channel_payed_money"
                render={v => (
                  <span className="money">{v ? (v / 100).toFixed(2) : 0}</span>
                )}
                className="sub-column"
              />
            </ColumnGroup>
            <ColumnGroup title="退款交易">
              <Column
                title={<span>腾路单数(笔)</span>}
                dataIndex="tengits_refound_count"
                key="tengits_refound_count"
                render={v => <span className="amount">{v}</span>}
                className="sub-column"
              />
              <Column
                title={<span>渠道单数(笔)</span>}
                dataIndex="channel_refound_count"
                key="channel_refound_count"
                render={v => <span className="amount">{v}</span>}
                className="sub-column"
              />
              <Column
                title={<span>应退金额(元)</span>}
                dataIndex="tengits_refound_money"
                key="tengits_refound_money"
                render={v => (
                  <span className="money">{v ? (v / 100).toFixed(2) : 0}</span>
                )}
                className="sub-column"
              />
              <Column
                title={<span>实退金额(元)</span>}
                dataIndex="channel_refound_money"
                key="channel_refound_money"
                render={v => (
                  <span className="money">{v ? (v / 100).toFixed(2) : 0}</span>
                )}
                className="sub-column"
              />
            </ColumnGroup>
            <Column
              title="对账结果"
              dataIndex="recon_status"
              key="recon_status"
              render={v => BALANCE_ACCOUNT_RESULT[v]}
            />
            <Column
              title="操作"
              fixed="right"
              width="auto"
              render={item => (
                <span>
                  <Link
                    to={{
                      pathname: `/balance/dayrecords`,
                      state: { fields: { ...item } }
                    }}
                  >
                    查看
                  </Link>
                </span>
              )}
            />
          </Table>
        )}
      </Subscribe>
    );
  }
}
