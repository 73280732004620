import React, { Component } from "react";
import { Card, Button, message, Modal } from "antd";
import { Provider, Subscribe } from "unstated";
import "./index.less";
import model from "./model";
import { PLATE_COLOR, CAR_FEE_TYPE } from "../../constants";
export default class OrderDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      modaledImg: null
    };
  }
  componentDidMount() {
    const {
      match: {
        params: { id }
      }
    } = this.props;
    model.getOrderDetail(id).catch(e => {
      console.log(e);
      message.error(e.message || "获取订单详情失败");
    });
  }
  render() {
    const { history } = this.props;
    return (
      <div className="page-content OrderDetail">
        <Card className="header">
          <Button onClick={history.goBack} icon="left" className="goback">
            返回
          </Button>
          <div className="title">订单详情</div>
        </Card>
        <Provider>
          <Subscribe to={[model]}>
            {({ state: { fields } }) => (
              <Card className="content">
                <Card className="box" title="订单基本信息">
                  <div className="info">
                    <div className="item">
                      <span className="key">车道订单号</span>
                      <span className="value">{fields.feeSystemTradeNo}</span>
                    </div>
                    <div className="item">
                      <span className="key">订单创建时间</span>
                      <span className="value">{fields.createTime}</span>
                    </div>
                    <div className="item">
                      <span className="key">收费员编号</span>
                      <span className="value">{fields.casherNumber}</span>
                    </div>
                    <div className="item">
                      <span className="key">原始订单金额(元)</span>
                      <span className="value">
                        {fields.totalFee
                          ? (fields.totalFee / 100).toFixed(2)
                          : 0}
                      </span>
                    </div>
                    <div className="item">
                      <span className="key">应结订单金额(元)</span>
                      <span className="value">
                        {fields.totalFee
                          ? (fields.totalFee / 100).toFixed(2)
                          : 0}
                      </span>
                    </div>
                    <div className="item">
                      <span className="key">实际支付金额(元)</span>
                      <span className="value">
                        {fields.payFee ? (fields.payFee / 100).toFixed(2) : 0}
                      </span>
                    </div>
                    <div className="item">
                      <span className="key">订单优惠金额(元)</span>
                      <span className="value">{0}</span>
                    </div>
                    <div className="item">
                      <span className="key">单张优惠券金额(元)</span>
                      <span className="value">{0}</span>
                    </div>
                    <div className="item">
                      <span className="key">使用优惠券数量</span>
                      <span className="value">{0}</span>
                    </div>
                    <div className="item">
                      <span className="key">费额表版本</span>
                      <span className="value">{fields.dataVersion || "-"}</span>
                    </div>
                  </div>
                </Card>
                <Card className="box" title="车辆信息">
                  <div className="info">
                    <div className="item">
                      <span className="key">车牌号</span>
                      <span className="value">{fields.card_no || "-"}</span>
                    </div>
                    <div className="item">
                      <span className="key">车牌颜色</span>
                      <span className="value">
                        {PLATE_COLOR[fields.card_color] || "-"}
                      </span>
                    </div>
                    <div className="item">
                      <span className="key">车辆收费类型</span>
                      <span className="value">
                        {CAR_FEE_TYPE[fields.car_fee_type] || "-"}
                      </span>
                    </div>
                  </div>
                </Card>
                <Card className="box" title="腾路订单">
                  <div className="info">
                    <div className="item">
                      <span className="key">腾路订单号</span>
                      <span className="value">{fields.flow_no}</span>
                    </div>
                    <div className="item">
                      <span className="key">订单创建时间</span>
                      <span className="value">{fields.ylx_createTime}</span>
                    </div>
                  </div>
                </Card>
                <Card className="box" title="车辆出口入信息">
                  <div className="info">
                    <div className="item">
                      <span className="key">入口站名称</span>
                      <span className="value">{fields.inSiteName}</span>
                    </div>
                    <div className="item">
                      <span className="key">入口车道编号</span>
                      <span className="value">{fields.inLaneName}</span>
                    </div>
                    <div className="item">
                      <span className="key">进入时间</span>
                      <span className="value">{fields.inSiteTime}</span>
                    </div>
                    <div className="item">
                      <span className="key">出口站名称</span>
                      <span className="value">{fields.outSiteName}</span>
                    </div>
                    <div className="item">
                      <span className="key">出口车道编号</span>
                      <span className="value">{fields.outLaneName}</span>
                    </div>
                    <div className="item">
                      <span className="key">驶出时间</span>
                      <span className="value">{fields.outSiteTime}</span>
                    </div>
                    <div className="item">
                      <span className="key">驶入图片</span>
                      {fields.inPic ? (
                        <span
                          className="value"
                          style={{ background: "transparent" }}
                        >
                          <img
                            src={model.getCarPic(fields.inPic)}
                            alt=""
                            className="carImg"
                            onClick={() =>
                              this.setState({ modaledImg: fields.inPic })
                            }
                          />
                        </span>
                      ) : (
                        <span className="value">-</span>
                      )}
                    </div>
                    <div className="item">
                      <span className="key">驶出图片</span>
                      {fields.outPic ? (
                        <span
                          className="value"
                          style={{ background: "transparent" }}
                        >
                          <img
                            src={model.getCarPic(fields.outPic)}
                            alt=""
                            className="carImg"
                            onClick={() =>
                              this.setState({ modaledImg: fields.outPic })
                            }
                          />
                        </span>
                      ) : (
                        <span className="value">-</span>
                      )}
                    </div>
                  </div>
                </Card>
              </Card>
            )}
          </Subscribe>
        </Provider>
        {this.state.modaledImg ? (
          <Modal
            width={960}
            wrapClassName="OrderDetail-img-modal"
            visible={true}
            footer={null}
            onCancel={() => {
              this.setState({
                modaledImg: null
              });
            }}
          >
            <img style={{ width: "100%" }} src={model.getCarPic(this.state.modaledImg)} />
          </Modal>
        ) : null}
      </div>
    );
  }
}
