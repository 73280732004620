import React, { Component } from "react";
import { Table, Divider, message, Modal, Icon } from "antd";
import { Subscribe } from "unstated";
import model from "./model";
import { Link } from "react-router-dom";
import { USER_ROLE, PRIVIL_TYPE } from "../../constants";
const { Column } = Table;

export default class UserList extends Component {
  componentDidMount() {
    model
      .filter({
        condition: "",
        pageIndex: 1,
        pageCount: 10
      })
      .then(() => {
        model.queryUsers().catch(e => message.error(e.message));
      });
  }

  willToggleFrizingStatus(item) {
    let operate = item.del_flag ? "启用" : "停用";
    Modal.confirm({
      title: "提示",
      content: `是否${operate}该账号`,
      onOk() {
        model
          .toggleUserFrizeStatus(item)
          .then(message.success.bind(message, `${operate}成功`))
          .catch(e => message.error(e.message || `${operate}失败`));
      },
      onCancel() {}
    });
  }
  willRestPassword(item) {
    const operate = "重置";
    Modal.confirm({
      title: "提示",
      content: `是否确认重置${item.nick_name}的密码`,
      onOk() {
        model
          .resetUserPassword(item.id)
          .then(message.success.bind(message, `${operate}成功`))
          .catch(e => message.error(e.message || `${operate}失败`));
      },
      onCancel() {}
    });
  }

  onChange({ current, pageSize }, filters, sorter) {
    model
      .filter({
        condition: "",
        pageIndex: current,
        pageCount: pageSize
      })
      .then(() => {
        model.queryUsers().catch(e => message.error(e.message));
      });
  }
  render() {
    const { scrollX } = this.props;

    return (
      <Subscribe to={[model]}>
        {model => (
          <Table
            pagination={{
              ...model.state.pagination,
              showSizeChanger: true,
              showQuickJumper: true,
              showTotal: total => `共${total}条数据`
            }}
            onChange={this.onChange.bind(this)}
            scroll={{ x: !!scrollX ? true : false }}
            rowKey="id"
            className="list"
            dataSource={model.state.list}
          >
            <Column title="姓名" dataIndex="name" key="name" />
            <Column title="账号" dataIndex="nick_name" key="nick_name" />
            <Column
              title="工号"
              dataIndex="work_number"
              key="work_number"
              render={v => (!!v ? v : "--")}
            />
            <Column title="手机号" dataIndex="phone" key="phone" />
            <Column
              title="权限站点"
              dataIndex="station_names"
              key="station_names"
            />
            <Column
              title="角色"
              dataIndex="role"
              key="role"
              render={id => USER_ROLE[id]}
            />

            <Column
              title="下载权限"
              key="privil_type"
              dataIndex="privil_type"
              render={v => (v ? <Icon type="check" /> : null)}
            />
            <Column
              title="退款权限"
              key="refund_privil"
              dataIndex="refund_privil"
              render={v => (v ? <Icon type="check" /> : null)}
            />
            <Column
              title="差错账处理权限"
              key="misacc_privil"
              dataIndex="misacc_privil"
              render={v => (v ? <Icon type="check" /> : null)}
            />
            <Column
              title="报表管理/打印"
              key="classes_privil"
              dataIndex="classes_privil"
              render={v => (v ? <Icon type="check" /> : null)}
            />

            <Column
              title="操作"
              key="action"
              fixed="right"
              width="auto"
              render={item => (
                <span>
                  <a
                    href="javascript:;"
                    onClick={this.willRestPassword.bind(this, item)}
                  >
                    重置密码
                  </a>
                  <Divider type="vertical" />
                  <Link
                    to={{
                      pathname: `/users/edit/${item.id}`,
                      state: {
                        modal: true,
                        fields: { ...item },
                        from: this.props.location
                      }
                    }}
                  >
                    编辑
                  </Link>
                  <Divider type="vertical" />
                  <a
                    href="javascript:;"
                    onClick={this.willToggleFrizingStatus.bind(this, item)}
                  >
                    {item.del_flag ? "启用" : "停用"}
                  </a>
                </span>
              )}
            />
          </Table>
        )}
      </Subscribe>
    );
  }
}
