import React, { Component } from "react";
import { Table, Divider, message } from "antd";
import { Link } from "react-router-dom";
const { Column, ColumnGroup } = Table;
import { Subscribe } from "unstated";
import model from "./model";
import { getSites, getPaymentSetting } from "../../service";
import moment from "moment";
import auth from "../../auth";
import {
  TRADING_TYPE,
  TRADING_TYPE_FILTER,
  ERROR_TYPE_FILTER,
  ERROR_TYPE
} from "../../constants";
const onCell = record => {
  if (record.pay_flow_count > 1) {
    return {
      style: {
        color: "red"
      }
    };
  }
};
export default class BalanceDayRecordList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sites: [],
      trading_channels: [],
      trading_manners: []
    };
  }
  componentDidMount() {
    const {
      location: { state }
    } = this.props;
    const { date } = state.fields;

    getSites()
      .then(({ sites, user }) => {
        sites = sites.map(s => ({ value: s.id, text: s.name }));
        this.setState({ sites });
        model
          .filter({
            merchant_id: user.merchant_id + "",
            site_id: "",
            pageIndex: 1,
            pageCount: 10,
            keyword: "",
            trading_type: "",
            trading_manner: "",
            trading_status: "4" /*TRADING_SUCCESS*/,
            trading_channel: "",
            error_type: "",
            startTime: moment(date)
              .startOf("day")
              .format("YYYY-MM-DD HH:mm:ss"),
            endTime: moment(date)
              .endOf("day")
              .format("YYYY-MM-DD HH:mm:ss")
          })
          .then(() => {
            model
              .getBlanceRecodesDetailOfDay()
              .catch(e => message.error(e.message));
          });
      })
      .catch(e => {
        console.log(e);
        message.error("获取站点失败");
      });

    getPaymentSetting()
      .then(res => this.setState({ ...res }))
      .catch(console.log);
  }
  onChange({ current, pageSize }, filters, sorter) {
    const {
      site_id,
      trading_type,
      trading_channel,
      trading_manner,
      error_type
    } = filters;
    const { sites } = this.state;

    model
      .filter({
        pageIndex: model.state.filter.pageIndex !== current ? current : 1,
        pageCount: pageSize,
        ...(site_id ? { site_id: site_id[0] || "" } : {}),
        ...(trading_type ? { trading_type: trading_type[0] || "" } : {}),
        ...(trading_channel
          ? { trading_channel: trading_channel[0] || "" }
          : {}),
        ...(trading_manner ? { trading_manner: trading_manner[0] || "" } : {}),
        ...(error_type ? { error_type: error_type[0] || "" } : {})
      })
      .then(() => {
        model
          .getBlanceRecodesDetailOfDay()
          .catch(e => message.error(e.message));
      });
  }
  render() {
    const { scrollX } = this.props;

    let site_options = () => {
      if (this.state.sites.length > 1) {
        return {
          filterMultiple: false,
          filteredValue: model.state.filter.site_id
            ? [model.state.filter.site_id + ""]
            : null,
          filters: this.state.sites
        };
      }
      return {};
    };
    let trading_type_options = () => ({
      filterMultiple: false,
      filteredValue: model.state.filter.trading_type
        ? [model.state.filter.trading_type + ""]
        : null,
      filters: TRADING_TYPE_FILTER
    });
    let trading_manner_options = () =>
      this.state.trading_manners.length > 1
        ? {
            filterMultiple: false,
            filteredValue: model.state.filter.trading_manner
              ? [model.state.filter.trading_manner + ""]
              : null,
            filters: this.state.trading_manners
          }
        : {};
    let trading_channel_options = () =>
      this.state.trading_channels.length > 1
        ? {
            filterMultiple: false,
            filteredValue: model.state.filter.trading_channel
              ? [model.state.filter.trading_channel + ""]
              : null,
            filters: this.state.trading_channels
          }
        : {};
    let error_type_options = () => ({
      filterMultiple: false,
      filteredValue: model.state.filter.error_type
        ? [model.state.filter.error_type + ""]
        : null,
      filters: ERROR_TYPE_FILTER
    });
    return (
      <Subscribe to={[model]}>
        {model => (
          <Table
            rowKey="cczid"
            pagination={{
              ...model.state.pagination,
              showSizeChanger: true,
              showQuickJumper: true,
              showTotal: total => `共${total}条数据`
            }}
            onChange={this.onChange.bind(this)}
            scroll={{ x: !!scrollX ? true : false }}
            className="list"
            dataSource={model.state.list}
            rowClassName={record => {
              if (record.error_type > 1) {
                return "Balance-error";
              }
            }}
          >
            <Column
              title="交易时间"
              dataIndex="trading_date"
              key="trading_date"
              onCell={onCell}
            />

            <Column
              title="收费站点"
              dataIndex="site_name"
              key="site_id"
              {...site_options()}
              onCell={onCell}
            />

            <Column
              title="车牌号"
              dataIndex="plate_number"
              key="plate_number"
              onCell={onCell}
            />

            <Column
              title="交易金额(元)"
              dataIndex="trading_money"
              key="trading_money"
              render={v => (
                <span className="money">{v ? (v / 100).toFixed(2) : 0}</span>
              )}
              onCell={onCell}
            />
            <Column
              title="手续费(元)"
              dataIndex="trading_fee"
              key="trading_fee"
              render={v => (
                <span className="money">{v ? (v / 100).toFixed(2) : 0}</span>
              )}
              onCell={onCell}
            />
            <Column
              title="结算金额(元)"
              dataIndex="income_money"
              key="income_money"
              render={v => (
                <span className="money">{v ? (v / 100).toFixed(2) : 0}</span>
              )}
              onCell={onCell}
            />
            <Column
              title="腾路订单号"
              dataIndex="tengits_order_number"
              key="tengits_order_number"
              onCell={onCell}
            />
            <Column
              title="腾路流水号"
              dataIndex="tengits_serial_number"
              key="tengits_serial_number"
              onCell={onCell}
            />
            <Column
              title="渠道流水号"
              dataIndex="channel_serial_number"
              key="channel_serial_number"
              onCell={onCell}
            />

            <Column
              title="交易类型"
              dataIndex="trading_type"
              key="trading_type"
              render={v => TRADING_TYPE[v]}
              {...trading_type_options()}
              onCell={onCell}
            />
            <Column
              title="交易方式"
              dataIndex="trading_manner"
              key="trading_manner"
              render={v => {
                for (let manner of this.state.trading_manners) {
                  if (manner.value.indexOf(v) >= 0) return manner.text;
                }
              }}
              {...trading_manner_options()}
              onCell={onCell}
            />
            <Column
              title="支付渠道"
              dataIndex="trading_channel"
              key="trading_channel"
              render={v => {
                for (let channel of this.state.trading_channels) {
                  if (channel.value == v) return channel.text;
                }
              }}
              {...trading_channel_options()}
              onCell={onCell}
            />
            <Column
              title="差错类型"
              dataIndex="error_type"
              key="error_type"
              fixed="right"
              width="auto"
              render={v => ERROR_TYPE[v]}
              {...error_type_options()}
              onCell={onCell}
            />
            <Column
              title="收费员编号"
              dataIndex="casher_number"
              key="casher_number"
              fixed="right"
              width="auto"
              onCell={onCell}
            />
          </Table>
        )}
      </Subscribe>
    );
  }
}
