import React, { Component } from "react";
import { Form, Button, Input, message } from "antd";
const Field = Form.Item;
import model from "./model";
import { SiteSelector, DateTimeRangePicker } from "../../components";
class Search extends Component {
  onSubmit(e) {
    const { form } = this.props;
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        model.querySealsRecords(values).catch(e => message.error(e.message));
      }
    });
  }

  onReset() {
    const { resetFields } = this.props.form;
    resetFields(["employeeNo", "date"]);

    model
      .resetLoginLogsQueryParamsAndFetch()
      .catch(e => message.error(e.message));
  }

  onStationChange(stationId) {
    model.querySealsRecords({ stationId }).catch(e => message.error(e.message));
  }

  componentDidMount(){
    model.querySealsRecords({ }).catch(e => message.error(e.message));
  }

  componentWillUnmount(){
    model.resetFilter();
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const date_options = {
      initialValue: [null, null]
    };

    return (
      <Form
        layout="inline"
        className="search"
        onSubmit={this.onSubmit.bind(this)}
      >
        {/* <Field label="收费站点">
          {getFieldDecorator("stationId")(
            <SiteSelector onChange={this.onStationChange.bind(this)} />
          )}
        </Field> */}

        <Field label="日期">
          {getFieldDecorator("date", date_options)(
            <DateTimeRangePicker showTime={false} format="YYYY-MM-DD" />
          )}
        </Field>
        <Field label="&emsp;关键词">
          {getFieldDecorator("employeeNo")(<Input placeholder="账号/姓名" />)}
        </Field>
        <Button type="primary" htmlType="submit">
          查询
        </Button>
        <Button htmlType="button" onClick={() => this.onReset()}>
          重置
        </Button>
      </Form>
    );
  }
}

export default Form.create()(Search);
