import React, { Component } from "react";
import { Form, Button, Input, Select, Modal, Checkbox } from "antd";
import { Provider, Subscribe, Container } from "unstated";
import { SiteSelector } from "../../components";
import { USER_ROLE, ROLE_OPERATER, ROLE_MONITER } from "../../constants";
const Field = Form.Item;
class PureForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialFields: null
    };
  }
  onSubmit(e) {
    const { onSubmit, form, location } = this.props;
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        onSubmit &&
          onSubmit(
            location.state.fields && location.state.fields.id
              ? { id: location.state.fields.id, ...values }
              : values
          );
      }
    });
  }

  onCancel() {
    this.props.onCancel && this.props.onCancel();
  }
  componentDidMount() {
    const { model, form, location } = this.props;

    if (location.state.fields) {
      let fields = { ...location.state.fields };
      let initialFields = {};
      initialFields.station_ids = (
        (fields.station_ids || "").split(",") || []
      ).map(v => +v);
      initialFields.refund_privil = !!fields.refund_privil;
      initialFields.misacc_privil = !!fields.misacc_privil;
      initialFields.privil_type = !!fields.privil_type;
      initialFields.classes_privil = !!fields.classes_privil;
      initialFields.role = +fields.role;
      initialFields.name = fields.name;
      initialFields.phone = fields.phone;
      initialFields.work_number = fields.work_number;
      this.setState({
        initialFields: initialFields
      });
    } else {
      this.setState({
        initialFields: {}
      });
    }
  }
  componentWillUnmount() {}

  render() {
    const { model, form } = this.props;
    const { getFieldDecorator, getFieldValue } = form;
    const { location } = this.props;
    const { initialFields } = this.state;
    if (!initialFields) return null;
    const id =
      location.state && location.state.fields ? location.state.fields.id : null;
    const name_options = {
      rules: [
        {
          required: true,
          message: "请输入姓名"
        },
        {
          max: 10,
          min: 2,
          message: "名字长度为2-10"
        },
        {
          pattern: /^[\u4e00-\u9fa5]+$/,
          message: "请输入中文姓名"
        }
      ]
    };

    const phone_options = {
      rules: [
        {
          required: true,
          message: "请输入手机号"
        },
        {
          pattern: /^1\d{10}$/,
          message: "手机号格式不正确"
        }
      ]
    };

    const station_ids_options = {
      rules: [
        {
          required: true,
          message: "请选择站点"
        }
      ]
    };

    const role_options = {
      rules: [
        {
          required: true,
          message: "请选择角色"
        }
      ]
    };

    const work_number_options = {
      rules: [
        {
          required: true,
          message: "请输入工号"
        },
        {
          pattern: /^\d+$/,
          message: "工号格式不正确"
        }
      ]
    };

    return (
      <Form className="modal-form" onSubmit={this.onSubmit.bind(this)}>
        <Field label="用户角色">
          {getFieldDecorator("role", {
            initialValue: initialFields["role"],
            ...role_options
          })(
            <Select placeholder="请选择" disabled={!!id}>
              {[ROLE_OPERATER, ROLE_MONITER].map(key => (
                <Select.Option key={+key} value={+key}>
                  {USER_ROLE[key]}
                </Select.Option>
              ))}
            </Select>
          )}
        </Field>
        {getFieldValue("role") == ROLE_MONITER ? (
          <Field label="工号">
            {getFieldDecorator("work_number", {
              initialValue: initialFields["work_number"],
              ...work_number_options
            })(<Input disabled={!!id && !!initialFields["work_number"]} />)}
          </Field>
        ) : null}

        <Field label="用户姓名">
          {getFieldDecorator("name", {
            initialValue: initialFields["name"],
            ...name_options
          })(<Input />)}
        </Field>

        <Field label="用户手机号">
          {getFieldDecorator("phone", {
            initialValue: initialFields["phone"],
            ...phone_options
          })(<Input />)}
        </Field>
        <Field label="权限站点">
          {getFieldDecorator("station_ids", {
            initialValue: initialFields["station_ids"],
            ...station_ids_options
          })(
            <SiteSelector
              mode="multiple"
              showAllSites={true}
              notSelectFirst={true}
              placeholder="请选择"
            />
          )}
        </Field>

        <Field label="开通权限类别" style={{ display: "none" }}>
          {getFieldDecorator("privil_content", {
            initialValue: initialFields["privil_content"],
            initialValue: 1
          })(
            <Select disabled>
              <Select.Option value={1}>数据权限</Select.Option>
            </Select>
          )}
        </Field>
        <Field label="开通权限内容">
          {getFieldDecorator("refund_privil", {
            initialValue: initialFields["refund_privil"],
            valuePropName: "checked"
          })(<Checkbox>退款权限</Checkbox>)}
        </Field>
        <Field label=" ">
          {getFieldDecorator("misacc_privil", {
            initialValue: initialFields["misacc_privil"],
            valuePropName: "checked"
          })(<Checkbox>差错账权限</Checkbox>)}
        </Field>
        <Field label=" ">
          {getFieldDecorator("privil_type", {
            initialValue: initialFields["privil_type"],
            valuePropName: "checked"
          })(<Checkbox>下载权限</Checkbox>)}
        </Field>
        {1 ? (
          <Field label=" ">
            {getFieldDecorator("classes_privil", {
              initialValue: initialFields["classes_privil"],
              valuePropName: "checked"
            })(<Checkbox>报表管理/打印</Checkbox>)}
          </Field>
        ) : null}

        <div className="modal-form-footer">
          <Button
            className="modal-form-cancel"
            onClick={this.onCancel.bind(this)}
            disabled={model.state.submitting}
          >
            取消
          </Button>
          <Button
            className="modal-form-submit"
            type="primary"
            htmlType="submit"
            loading={model.state.submitting}
          >
            确定
          </Button>
        </div>
      </Form>
    );
  }
}

const WrapedPureForm = Form.create()(PureForm);

export default WrapedPureForm;
