import request from '../../libs/request';

export function getOrderDetail(flowNo) {
  return request('/data/trade/queryDetailOfPaymentOrder', { flowNo })
    .then(res => res.data)
    .then(data => {
      return {
        flow_no: data.ylx.flow_no,
        ylx_createTime: data.ylx.createTime,
        ...data.car,
        ...data.lane,
        ...data.base,
      }
    })
}

export function getCarPic(url) {
  return '/data/system/redirectCosUrl?bucket=xBoxBucket&url=' + url;
}