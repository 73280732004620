import React, { Component } from "react";
import { Table, message, Divider } from "antd";
import { Subscribe } from "unstated";
import model from "./model";
import { Link } from "react-router-dom";
const { Column } = Table;
const REPORT_TABLE_NAME = {
  batch: "班次报表",
  day: "日报表",
  month: "月度报表",
  year: "年度报表"
};
const REPORT_DATE_LENGTH = {
  batch: 10,
  day: 10,
  month: 7
};
import { TOLLER_SHIFT } from "../../constants";
export default class ChargeReportsList extends Component {
  onChange({ current, pageSize }) {
    model
      .filterChargeReports({
        pageIndex: current,
        pageCount: pageSize
      })
      .catch(e => message.error(e.message));
  }

  render() {
    const { scrollX } = this.props;
    const { type: reportType } = this.props.match.params;
    return (
      <Subscribe to={[model]}>
        {model => (
          <Table
            rowKey="id"
            pagination={{
              ...model.state.pagination,
              showSizeChanger: true,
              showQuickJumper: true,
              showTotal: total => `共${total}条数据`
            }}
            onChange={this.onChange.bind(this)}
            scroll={{ x: !!scrollX ? true : false }}
            rowKey="id"
            className="list"
            dataSource={model.state.list}
          >
            <Column
              title="日期"
              dataIndex="accountDate"
              key="accountDate"
              render={v => (v ? v.substr(0, 10) : null)}
            />
            <Column
              title="报表名称"
              key="stationName"
              render={item =>
                item.stationName +
                REPORT_TABLE_NAME[reportType] +
                (item.classesType ? "-" + TOLLER_SHIFT[item.classesType] : "")
              }
            />

            <Column
              title="操作"
              key="action"
              fixed="right"
              width="auto"
              render={item => (
                <>
                  <span>
                    <Link
                      key="1"
                      to={{
                        pathname: `/chargeReports/detail/${
                          reportType
                        }/${model.state.filter.stationId}/${
                          item.accountDate
                        }/${item.classesType || 0}`,
                        state: {
                          title:
                            item.stationName +
                            REPORT_TABLE_NAME[reportType]
                        }
                      }}
                    >
                      查看
                    </Link>
                  </span>

                  <span style={{marginLeft: "10px"}}>
                    <a onClick={() => model.exportChargeReports(item, reportType)}>导出</a>
                  </span>
                </>
              )}
            />
          </Table>
        )}
      </Subscribe>
    );
  }
}
