import React, { Component } from "react";
import { Form, Button, Input, Select, message } from "antd";
import moment from "moment";
const Field = Form.Item;
import { SiteSelector, MonthRangePicker } from "../../components";
import model from "./model";

class BalanceMonthlySearch extends Component {
  onSubmit(e) {
    const { form } = this.props;
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        let [startTime, endTime] = values.date;
        let filter = {
          pageIndex: 1
        };

        startTime
          ? (filter.startTime = moment(startTime).format("YYYY-MM-DD HH:mm:ss"))
          : null;
        endTime
          ? (filter.endTime = moment(endTime).format("YYYY-MM-DD HH:mm:ss"))
          : null;

        model.filter(filter).then(() => {
          model.getBalanceMonthly().catch(e => message.error(e.message));
        });
      }
    });
  }

  onReset() {
    const { resetFields, getFieldsValue } = this.props.form;

    resetFields(["date"]);
    const fields = getFieldsValue();
    model
      .filter({
        startTime: fields.date[0].format("YYYY-MM-DD HH:mm:ss"),
        endTime: fields.date[1].format("YYYY-MM-DD HH:mm:ss"),
        pageIndex: 1,
        pageCount: 10,
      })
      .then(() => {
        model.getBalanceMonthly().catch(e => message.error(e.message));
      });
  }
  render() {
    const { getFieldDecorator } = this.props.form;
    const date_options = {
      initialValue: [
        moment()
          .subtract(1, "months")
          .startOf("month"),
        moment().endOf("month")
      ]
    };

    return (
      <Form
        layout="inline"
        className="search"
        onSubmit={this.onSubmit.bind(this)}
      >
        <Field label="交易日期">
          {getFieldDecorator("date", date_options)(
            <MonthRangePicker />
          )}
        </Field>
        
        <Button type="primary" htmlType="submit">
          查询
        </Button>
        <Button htmlType="button" onClick={() => this.onReset()}>
          重置
        </Button>
        {/* {model.hasExportAuthority() ? <Button className="export" onClick={() => model.exportBalanceMonthly({ pageIndex: -1 })}>导出数据</Button> : null} */}
      </Form>
    );
  }
}

export default Form.create()(BalanceMonthlySearch);
