import Collection from '../../libs/collection';
import { getBlanceRecodesDetailOfDay, exportBlanceRecodesDetailOfDay } from './service'
import auth from '../../auth';
class BlanceRecodesDetailOfDay extends Collection {
  constructor(props){
    super(props);
    this.state = {
      ...this.state,
      summary: {}
    }
  }
  getBlanceRecodesDetailOfDay(filter) {
    return getBlanceRecodesDetailOfDay(this.state.filter)
      .then(res => {
        this.list(res.data, res.pagination);
        this.setState({
          summary: res.summary
        })
      })
  }

  exportBlanceRecodesDetailOfDay(params) {
    return auth.getUserInfo()
    .then((user) => {
      return exportBlanceRecodesDetailOfDay({...this.state.filter, ...params, merchant_name: user.merchant_name})
    })
  }

  hasExportAuthority(){
    return  auth.hasExportAuthority();
  }

}
export default new BlanceRecodesDetailOfDay()