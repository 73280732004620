import Collection from "../../libs/collection";
import {
  fetchOperationLogs,
} from "./service";
import moment from "moment";
class Model extends Collection {
  constructor(props) {
    super(props);
    this.initialFilter = {
      startDate: "",
      endDate: "",
      employeeNo: "",
      pageIndex: 1,
      pageCount: 10
    };
    this.state = {
      ...this.state,
      filter: { ...this.initialFilter }
    };
  }

  fetchOperationLogs() {
    return fetchOperationLogs({
      ...this.state.filter
    }).then(res => {
      this.list(res.data, res.pagination);
    });
  }

  filterOperationLogs(params) {
    return this.filter({
      ...params
    }).then(() => this.fetchOperationLogs());
  }

  querySealsRecords(query) {
    let [startDate, endDate] = query.date || [];
    delete query.date;
    let params = {
      ...query,
      startDate: startDate
        ? moment(startDate).format("YYYY-MM-DD")
        : this.state.startDate || "",
      endDate: endDate
        ? moment(endDate).format("YYYY-MM-DD")
        : this.state.endDate || ""
    };
    return this.filter({
      ...params,
      pageIndex: 1
    }).then(() => this.fetchOperationLogs());
  }

  resetOperationLogsQueryParamsAndFetch() {
    return this.filter({
      ...this.initialFilter
    }).then(() => this.fetchOperationLogs());
  }

  resetFilter(){
    return this.filter({
      ...this.initialFilter
    })
  }

  


}
export default new Model();
