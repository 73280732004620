import Collection from '../../libs/collection';
import { queryRefoundOrders, exportRefoundOrderRecords } from './service'
import auth from '../../auth';
class RefoundOrderRecordes extends Collection {
  queryRefoundOrders() {
    return queryRefoundOrders(this.state.filter)
      .then(res => {
        this.list(res.data, res.pagination)
      })
  }
  exportRefoundOrderRecords() {
    return exportRefoundOrderRecords({ ...this.state.filter, pageIndex: -1 })
  }
  hasExportAuthority(){
    return  auth.hasExportAuthority();
  }
}
export default new RefoundOrderRecordes()