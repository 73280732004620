import React from "react";
import { DatePicker } from "antd";
const { MonthPicker } = DatePicker;
import "./index.less";
export default class MonthRangePicker extends React.Component {
  constructor(props) {
    super(props);
    const value = props.value || [];
    this.state = {
      value: value,
      endOpen: false
    };
  }

  componentWillReceiveProps(nextProps) {
    // Should be a controlled component.
    if ("value" in nextProps) {
      const value = nextProps.value;
      this.setState(value);
    }
  }

  handleStartOpenChange(open) {
    if (!open) {
      this.setState({ endOpen: true });
    }
  }

  handleEndOpenChange(open) {
    this.setState({ endOpen: open });
  }

  onStartChange(value) {
    const [start, end] = this.state.value;
    this.setState({
      value: [value, end]
    });
    this.props.onChange && this.props.onChange([value, end]);
  }
  onEndChange(value) {
    const [start, end] = this.state.value;
    this.setState({
      value: [start, value]
    });
    this.props.onChange && this.props.onChange([start, value]);
  }

  disabledStartDate(startValue) {
    const [, endValue] = this.state.value;
    if (!startValue || !endValue) {
      return false;
    }
    return startValue.valueOf() > endValue.valueOf();
  }

  disabledEndDate(endValue) {
    const [startValue] = this.state.value;
    if (!endValue || !startValue) {
      return false;
    }
    return endValue.valueOf() <= startValue.valueOf();
  }

  render() {
    const { value, endOpen } = this.state;
    return (
      <div className="MonthRangePicker">
        <MonthPicker
          allowClear={false}
          className="MonthRangePicker__item"
          value={value[0]}
          placeholder="开始日期"
          onChange={this.onStartChange.bind(this)}
          onOpenChange={this.handleStartOpenChange.bind(this)}
          disabledDate={this.disabledStartDate.bind(this)}
        />

        <MonthPicker
          allowClear={false}
          className="MonthRangePicker__item"
          value={value[1]}
          placeholder="结束日期"
          onChange={this.onEndChange.bind(this)}
          open={endOpen}
          onOpenChange={this.handleEndOpenChange.bind(this)}
          disabledDate={this.disabledEndDate.bind(this)}
        />
      </div>
    );
  }
}
