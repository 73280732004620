import React, { Component } from 'react';
import { Modal } from 'antd';
import { Provider, Subscribe } from 'unstated';
import model from './model.js';
import Form from './form.js';
export default class UserInfo extends Component {
  handleCancel(e) {
    const { history } = this.props;
    history.goBack();
  }

  onSubmit(values) {
    const { history } = this.props;
    history.goBack();
  }
  render() {

    return (
      <Provider>
        <Subscribe to={[model]}>
          {model => (
            <Modal
              maskClosable={false}
              title={"原始订单信息"}
              visible={true}
              onOk={this.onSubmit.bind(this)}
              onCancel={this.handleCancel.bind(this)}
              footer={null}
            >
              <Form model={model} onCancel={this.handleCancel.bind(this)} onSubmit={this.onSubmit.bind(this)} location={this.props.location} />
            </Modal>)
          }
        </Subscribe>
      </Provider>
    )
  }
}