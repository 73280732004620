import React, { Component } from 'react';
import { Form, Button, Input, Select, Modal } from 'antd';
import auth from '../../auth';
import { USER_ROLE } from '../../constants';
const Field = Form.Item;
class PureForm extends Component {

  onSubmit(e) {
    const { onSubmit, form } = this.props;
    e.preventDefault();
    onSubmit && onSubmit()
  }

  onCancel() {
    this.props.onCancel && this.props.onCancel()
  }
  componentDidMount() {
    const { model, form } = this.props;

  }
  componentWillUnmount() {

  }
  render() {
    const { model, form } = this.props;
    const user = auth.user || {}
    return (
      <Form className="modal-form" onSubmit={this.onSubmit.bind(this)}>
        <Field label="账号">{user.nick_name}</Field>
        <Field label="姓名">{user.name}</Field>
        <Field label="角色">{USER_ROLE[user.role]}</Field>
        <Field label="手机号码">{user.phone}</Field>

        <div className="modal-form-footer">
          <Button className="modal-form-submit" type="primary" htmlType="submit" >关闭</Button>
        </div>
      </Form>
    )
  }
}

const WrapedPureForm = Form.create()(PureForm);

export default WrapedPureForm;


