import React, { Component } from 'react';
import { Form, Button, Input, Select } from 'antd';
const Field = Form.Item;
class PureForm extends Component {

  onSubmit(e) {
    const { onSubmit, form } = this.props;
    e.preventDefault();
    onSubmit && onSubmit()
  }

  onCancel() {
    this.props.onCancel && this.props.onCancel()
  }
  componentDidMount() {
    const { model, form } = this.props;

  }
  componentWillUnmount() {

  }
  render() {
    const { model, form, location: { state: { fields } } } = this.props;
    return (
      <Form className="modal-form" onSubmit={this.onSubmit.bind(this)}>
        <Field label="腾路订单号">{fields.orderNo}</Field>
        <Field label="渠道流水号">{fields.otherTrxNo}</Field>
        <Field label="应结订单金额(元)">{fields.payFee ? (fields.payFee / 100).toFixed(2) : 0}</Field>
        <Field label="实际支付金额(元)">{fields.payFee ? (fields.payFee / 100).toFixed(2) : 0}</Field>
        <Field label="支付渠道">{fields.payChannelName}</Field>
        <Field label="支付方式">{fields.payTypeName}</Field>

        <div className="modal-form-footer">
          <Button className="modal-form-submit" type="primary" htmlType="submit" >关闭</Button>
        </div>
      </Form>
    )
  }
}

const WrapedPureForm = Form.create()(PureForm);

export default WrapedPureForm;


