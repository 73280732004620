import request from "../../libs/request";
import { getUserInfo } from "../../service";

export function queryTollers(params) {
  return getUserInfo().then(user =>
    request("/data/system/queryTollCollector", {
      ...params,
      condition: params.condition || "",
      merchant_id: user.merchant_id
    })
  );
}
export function removeToller(id) {
  return getUserInfo().then(user =>
    request("/data/system/delTollCollector", {
      id
    })
  );
}
