import React, { Component } from "react";
import { Table, Divider, message } from "antd";
import { Link } from "react-router-dom";
const { Column, ColumnGroup } = Table;
import { Subscribe } from "unstated";
import model from "./model";
import { getUserInfo, getPaymentSetting } from "../../service";
import moment from "moment";
import {
  BALANCE_ACCOUNT_RESULT,
  BALANCE_CACHE_ERROR,
  BALANCE_ACCOUNT_ERROR,
  BALANCE_ACCOUNT_AND_CACHE_ERROR
} from "../../constants";

export default class BalanceMonthlyList extends Component {
  componentDidMount() {
    getUserInfo()
      .then(user => {
        model
          .filter({
            merchantId: user.merchant_id + "",
            pageIndex: 1,
            pageCount: 10,
            startTime: moment()
              .subtract(1, "months")
              .startOf("month"),
            endTime: moment().endOf("month")
          })
          .then(() => {
            model.getBalanceMonthly().catch(e => message.error(e.message));
          });
      })
      .catch(e => {
        console.log(e);
        message.error("获取用户信息");
      });
  }
  onChange({ current, pageSize }, filters, sorter) {
    model
      .filter({
        pageIndex: current,
        pageCount: pageSize
      })
      .then(() => {
        model.getBalanceMonthly().catch(e => message.error(e.message));
      });
  }
  render() {
    const { scrollX } = this.props;
    return (
      <Subscribe to={[model]}>
        {model => (
          <Table
            rowKey="id"
            pagination={{
              ...model.state.pagination,
              showSizeChanger: true,
              showQuickJumper: true,
              showTotal: total => `共${total}条数据`
            }}
            onChange={this.onChange.bind(this)}
            scroll={{ x: !!scrollX ? true : false }}
            className="list"
            dataSource={model.state.list}
            rowClassName={record => {
              if (
                record.recon_status === BALANCE_CACHE_ERROR ||
                record.recon_status === BALANCE_ACCOUNT_ERROR ||
                record.recon_status === BALANCE_ACCOUNT_AND_CACHE_ERROR
              ) {
                return "Balance-error";
              }
            }}
          >
            <Column
              title="交易月份"
              dataIndex="date"
              key="date"
             
            />

            <ColumnGroup title="合计(元)">
              <Column
                title={<span>支付实收金额(元)</span>}
                dataIndex="payAmount"
                key="payAmount"
                render={v => (
                  <span className="money">{v ? (v / 100).toFixed(2) : 0}</span>
                )}
                className="sub-column"
              />
              <Column
                title={<span>退款金额</span>}
                dataIndex="refundAmount"
                key="refundAmount"
                render={v => (
                  <span className="money">{v ? (v / 100).toFixed(2) : 0}</span>
                )}
                className="sub-column"
              />

              <Column
                title={<span>实收总金额</span>}
                dataIndex="totalAmount"
                key="totalAmount"
                render={v => (
                  <span className="money">{v ? (v / 100).toFixed(2) : 0}</span>
                )}
                className="sub-column"
              />

              <Column
                title={<span>手续费</span>}
                dataIndex="fee"
                key="fee"
                render={v => (
                  <span className="money">{v ? (v / 100).toFixed(2) : 0}</span>
                )}
                className="sub-column"
              />
              <Column
                title={<span>结算金额</span>}
                dataIndex="settlementAmount"
                key="settlementAmount"
                render={v => (
                  <span className="money">{v ? (v / 100).toFixed(2) : 0}</span>
                )}
                className="sub-column"
              />
            </ColumnGroup>
            <ColumnGroup title="微信支付(元)">
              <Column
                title={<span>支付实收金额(元)</span>}
                dataIndex="wechatPayAmount"
                key="wechatPayAmount"
                render={v => (
                  <span className="money">{v ? (v / 100).toFixed(2) : 0}</span>
                )}
                className="sub-column"
              />
              <Column
                title={<span>退款金额</span>}
                dataIndex="wechatRefundAmount"
                key="wechatRefundAmount"
                render={v => (
                  <span className="money">{v ? (v / 100).toFixed(2) : 0}</span>
                )}
                className="sub-column"
              />

              <Column
                title={<span>实收总金额</span>}
                dataIndex="wechatTotalAmount"
                key="wechatTotalAmount"
                render={v => (
                  <span className="money">{v ? (v / 100).toFixed(2) : 0}</span>
                )}
                className="sub-column"
              />

              <Column
                title={<span>手续费</span>}
                dataIndex="wechatFee"
                key="wechatFee"
                render={v => (
                  <span className="money">{v ? (v / 100).toFixed(2) : 0}</span>
                )}
                className="sub-column"
              />
              <Column
                title={<span>结算金额</span>}
                dataIndex="wechatSettlementAmount"
                key="wechatSettlementAmount"
                render={v => (
                  <span className="money">{v ? (v / 100).toFixed(2) : 0}</span>
                )}
                className="sub-column"
              />
            </ColumnGroup>

            <ColumnGroup title="支付宝(元)">
              <Column
                title={<span>支付实收金额(元)</span>}
                dataIndex="alipayPayAmount"
                key="alipayPayAmount"
                render={v => (
                  <span className="money">{v ? (v / 100).toFixed(2) : 0}</span>
                )}
                className="sub-column"
              />
              <Column
                title={<span>退款金额</span>}
                dataIndex="alipayRefundAmount"
                key="alipayRefundAmount"
                render={v => (
                  <span className="money">{v ? (v / 100).toFixed(2) : 0}</span>
                )}
                className="sub-column"
              />

              <Column
                title={<span>实收总金额</span>}
                dataIndex="alipayTotalAmount"
                key="alipayTotalAmount"
                render={v => (
                  <span className="money">{v ? (v / 100).toFixed(2) : 0}</span>
                )}
                className="sub-column"
              />

              <Column
                title={<span>手续费</span>}
                dataIndex="alipayFee"
                key="alipayFee"
                render={v => (
                  <span className="money">{v ? (v / 100).toFixed(2) : 0}</span>
                )}
                className="sub-column"
              />
              <Column
                title={<span>结算金额</span>}
                dataIndex="alipaySettlementAmount"
                key="alipaySettlementAmount"
                render={v => (
                  <span className="money">{v ? (v / 100).toFixed(2) : 0}</span>
                )}
                className="sub-column"
              />
            </ColumnGroup>
          </Table>
        )}
      </Subscribe>
    );
  }
}
