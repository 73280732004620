import Collection from '../../libs/collection';
import { queryUsers, toggleUserFrizeStatus, resetUserPassword } from './service'
class Users extends Collection {
  queryUsers() {
    return queryUsers(this.state.filter)
      .then(res => {
        this.list(res.data, res.pagination)
      })
  }

  toggleUserFrizeStatus(item) {
    const type = item.del_flag ? 2 : 1;
    return toggleUserFrizeStatus(item.id, type).then(res => {
      this.edit({...item, del_flag: !item.del_flag})
    })
  }

  resetUserPassword(id){
    return resetUserPassword(id)
  }

}
export default new Users()