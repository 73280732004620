import moment from 'moment';
const schema = {
  stationId: {
    value: '',
    name: '收费站点',
    rules: [
      {
        required: true,
        message: '清选择收费站点'
      },
    ]
  },
  sealBillDate: {
    value: '',
    name: '封账日期',
    rules: [
      {
        required: true,
        message: '清选择封账日期'
      },
      {
        validator: function(rule, date, callback) {
          if (moment(date).isAfter(moment())) {
            callback("封账日期不能大于当前时间");
          }
          callback();
        }
      }
    ]
  },
  
  tollCollectorGroup: {
    value: '',
    name: '收费班组',
    rules: [
      {
        required: true,
        message: '请输入收费班组'
      },
      {
        pattern: /^\d+$/,
        message: '收费班组只能是数字'
      },
    ]
  },
  
  orderNo: {
    value: '',
    name: '订单号',
    rules: [
      {
        required: true,
        message: '请输入订单号'
      },
    ]
  },
  
  
}

export default schema;