import React, { Component } from 'react';
import { Form, Button, Input, Select, message } from 'antd';
const Field = Form.Item;
import { DateTimeRangePicker } from '../../components';
import moment from 'moment';
import model from './model';
class BalanceErrorSearch extends Component {
  onSubmit(e) {
    const { form } = this.props;
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        let [startTime, endTime] = values.date;
        let filter = {
          pageIndex: 1,
        };
        startTime ? filter.startTime = moment(startTime).format('YYYY-MM-DD HH:mm:ss') : null;
        endTime ? filter.endTime = moment(endTime).format('YYYY-MM-DD HH:mm:ss') : null;
        values.keyword && values.keyword.trim() ? filter.keyword = values.keyword : null;
        model.filter(filter).then(() => {
          model.getErrorBalanceRecords()
            .catch(e => message.error(e.message))
        })
      }
    });

  }

  onReset() {
    const { resetFields, getFieldsValue } = this.props.form;
    resetFields();
    const fields = getFieldsValue();
    resetFields();
    model.filter({
      startTime: fields.date[0].format('YYYY-MM-DD HH:mm:ss'),
      endTime: fields.date[1].format('YYYY-MM-DD HH:mm:ss'),

      site_id: '',
      pageIndex: 1,
      pageCount: 10,
      handle_status: '',
      error_type: '',
      keyword: '',
      channel_id: "",
      pay_type_id: "",
    }).then(() => {
      model.getErrorBalanceRecords()
        .catch(e => message.error(e.message))
    })
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const date_options = {
      initialValue: [moment().subtract(7, 'days').startOf('day'), moment().endOf('day')]
    }
    return (
      <Form layout="inline" className="search" onSubmit={this.onSubmit.bind(this)}>
        <Field label="交易日期">
          {getFieldDecorator('date', date_options)(<DateTimeRangePicker />)}
        </Field>

        <Field label="关键词">{getFieldDecorator('keyword')(<Input placeholder="车牌号/腾路订单号/操作员/流水号" />)}</Field>
        <Button type="primary" htmlType="submit">查询</Button>
        <Button htmlType="button" onClick={() => this.onReset()}>重置</Button>
        {model.hasExportAuthority() ? <Button className="export" onClick={() => model.exportBalanceErrors({ pageIndex: -1 })}>导出数据</Button> : null}
      </Form>
    )
  }
}

export default Form.create()(BalanceErrorSearch)