import { Container } from 'unstated';
import { addUser, editUser } from './service';
class UserForm extends Container {
  constructor(props) {
    super(props);
    this.initialState = {
      submitting: false,
      fields: {}
    }
    this.state = {
      ...this.initialState
    }
    
  }
  
  submit(values){
    this.setState({submitting: true})
    let fn = values.id ? editUser : addUser;
    let form = {...values, 
      station_ids: values.station_ids.join(','),
      refund_privil: values.refund_privil ? 1 : 0,
      misacc_privil: values.misacc_privil ? 1 : 0,
      privil_type: values.privil_type ? 1 : 0,
      classes_privil: values.classes_privil ? 1 : 0,
      role: +values.role,
      work_number: values.work_number || ''
    }
    return fn(form)
    .then(id => {
      this.setState({submitting: false})
      return {id,...form};
    })
    .catch(e => {
      this.setState({submitting: false})
      throw e;
    });
    
  };

  getDetail(form){
    return this.setState({
      fields: {...form}
    })
  }
    

  clearForm(){
    this.setState({
      ...this.initialState
    })
  };


}

export default new UserForm();