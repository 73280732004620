import request from "../../libs/request";
import exportFile from "../../libs/exportFile";
export function queryPayedOrders(params) {
  return request("/data/trade/queryPayTrade", {
    ...params,
    fp_qqlsh: +params.fp_qqlsh
  });
}
export function exportPayedOrderRecords(params) {
  return exportFile("/data/trade/exportPayTrade", params);
}
