import React, { Component } from "react";
import {
  Form,
  Button,
  Input,
  Select,
  message,
  DatePicker,
  Spin,
  InputNumber
} from "antd";
import { SiteSelector } from "../../components";
import schema from "./schema";
import { CAR_FEE_TYPE } from "../../constants";
import model from "./model";
import moment from "moment";
import debounce from "../../libs/debounce";
const Field = Form.Item;

class PureForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tollShift: null,
      tollCollectorNo: null,
      tollCollectorName: null,
      vechileType: null,
      plateNo: null,
      amount: null,
      orderList: [],
      orderFetching: false
    };
    this.getOrderDetail = debounce(this.getOrderDetail, 800);
  }

  onSubmit(e) {
    const { onSubmit, form } = this.props;
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        if (!this.state.tollCollectorNo) {
          return message.error("校验订单失败");
        }
        let form = {
          tollShift: this.state.tollShift,
          tollCollectorNo: this.state.tollCollectorNo,
          tollCollectorName: this.state.tollCollectorName,
          vechileType: this.state.vechileType,
          plateNo: this.state.plateNo,
          amount: this.state.amount
        };
        for (let key in values) {
          form[key] =
            values[key] instanceof moment
              ? moment(values[key]).format("YYYY-MM-DD")
              : values[key];
        }
        onSubmit && onSubmit(form);
      }
    });
  }

  onCancel() {
    this.props.onCancel && this.props.onCancel();
  }

  componentDidMount() {
    const { form, location } = this.props;
    const fields = location.state.fields;
    if (!fields) return;

    let values = {};
    for (let key in schema) {
      values[key] = /\d{4}-\d{1,2}-\d{2}/.test(fields[key])
        ? moment(fields[key])
        : fields[key];
    }
    form.setFieldsValue(values);

    this.setState({
      tollShift: fields.tollShift,
      tollCollectorNo: fields.tollCollectorNo,
      tollCollectorName: fields.tollCollectorName,
      vechileType: fields.vechileType,
      plateNo: fields.plateNo,
      amount: fields.amount
    });
  }

  getOrderDetail(orderNo) {
    if (!orderNo || orderNo.length < 7) return;
    const { getFieldValue } = this.props.form;
    const stationId = getFieldValue("stationId") || -1;

    this.setState({
      orderList: [],
      orderFetching: true
    });
    model
      .getOrderDetail(orderNo, stationId)
      .then(data => {
        this.setState({
          orderFetching: false,
          orderList: data
        });
      })
      .catch(e => {
        console.log(e);
        this.setState({
          orderList: [],
          orderFetching: false
        });
        message.error(e.message || "校验订单失败");
      });
  }

  onOrderNoChange(value, option) {
    const data = option.props.data;
    this.setState({
      tollShift: data.tollShift,
      tollCollectorNo: data.tollCollectorNo,
      tollCollectorName: data.tollCollectorName,
      vechileType: data.vechileType,
      plateNo: data.plateNo,
      amount: data.amount
    });
  }

  render() {
    const { model, form } = this.props;
    const { getFieldDecorator } = form;

    return (
      <Form className="modal-form" onSubmit={this.onSubmit.bind(this)}>
        <Field label="收费站点">
          {getFieldDecorator("stationId", { rules: schema.stationId.rules })(
            <SiteSelector placeholder=" " notSelectFirst={true}/>
          )}
        </Field>
        <Field label="封账日期">
          {getFieldDecorator("sealBillDate", {
            initialValue: null,
            rules: schema.sealBillDate.rules
          })(<DatePicker placeholder=" "/>)}
        </Field>

        <Field label="收费班组">
          {getFieldDecorator("tollCollectorGroup", {
            rules: schema.tollCollectorGroup.rules
          })(<InputNumber />)}
        </Field>

        <Field label="订单号">
          {getFieldDecorator("orderNo", {
            rules: schema.orderNo.rules
          })(
            <Select
              defaultActiveFirstOption={false}
              showSearch={true}
              optionLabelProp={"value"}
              showArrow={false}
              placeholder="请输入订单号后7位"
              notFoundContent={
                this.state.orderFetching ? <Spin size="small" /> : null
              }
              filterOption={false}
              onSearch={this.getOrderDetail.bind(this)}
              onChange={this.onOrderNoChange.bind(this)}
              style={{ width: "100%" }}
            >
              {this.state.orderList.map(d => (
                <Select.Option key={d.orderNo} value={d.orderNo} data={d}>
                  {d.orderNo}
                </Select.Option>
              ))}
            </Select>
          )}
        </Field>

        {this.state.tollCollectorNo !== null ? (
          <Field label="收费员工号">{this.state.tollCollectorNo}</Field>
        ) : null}
        {this.state.tollCollectorName !== null ? (
          <Field label="收费员名称">{this.state.tollCollectorName}</Field>
        ) : null}
        {this.state.vechileType !== null ? (
          <Field label="车辆类型">{CAR_FEE_TYPE[this.state.vechileType]}</Field>
        ) : null}
        {this.state.plateNo !== null ? (
          <Field label="车牌号">{this.state.plateNo}</Field>
        ) : null}
        {this.state.amount !== null ? (
          <Field label="票据金额(元)">{this.state.amount ? (this.state.amount / 100).toFixed(2) : 0}</Field>
        ) : null}

        <div className="modal-form-footer">
          <Button
            className="modal-form-cancel"
            onClick={this.onCancel.bind(this)}
            disabled={model.state.submitting}
          >
            取消
          </Button>
          <Button
            className="modal-form-submit"
            type="primary"
            htmlType="submit"
            loading={model.state.submitting}
          >
            确定
          </Button>
        </div>
      </Form>
    );
  }
}

const WrapedPureForm = Form.create()(PureForm);

export default WrapedPureForm;
