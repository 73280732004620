import request from '../../libs/request';
import { getUserInfo } from '../../service';

export function addToller(params) {
  return getUserInfo().then(user => request('/data/system/addTollCollector', { ...params, merchant_id: user.merchant_id }).then((id) => ({ ...params, id })))
}

export function editToller(params) {
  return getUserInfo().then(user => request('/data/system/editTollCollector', { ...params, merchant_id: user.merchant_id }).then(() => params))
}
