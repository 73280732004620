import { Container } from 'unstated';
import { handleBalanceError } from './service';
class BalanceErrorHandlingModel extends Container {
  constructor(props) {
    super(props);
    this.initialState = {
      count: 0,
      submitting: false,
    }
    this.state = {
      ...this.initialState
    }
    
  }
  
  submit(values){
    this.setState({submitting: true})
    return handleBalanceError(values)
    .then(res => {
      this.setState({submitting: false})
      return res;
    })
    .catch(e => {
      this.setState({submitting: false})
      throw e;
    });
    
  };
    

  clearForm(){
    this.setState({
      ...this.initialState
    })
  };


}

export default new BalanceErrorHandlingModel();