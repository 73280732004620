import Collection from "../../libs/collection";
import { queryPayedOrders, exportPayedOrderRecords } from "./service";
import auth from "../../auth";
class PayedOrderRecordes extends Collection {
  constructor(props){
    super(props);
    this.indexKey = 'feeSystemTradeNo';
  }
  queryPayedOrders() {
    return queryPayedOrders(this.state.filter).then(res => {
      this.list(
        res.data.map(item => ({
          ...item,
          canRefound: item.payStatus == 4 && !item.refundOtherTrxNo
        })),
        res.pagination
      );
    });
  }
  exportPayedOrderRecords() {
    return exportPayedOrderRecords({ ...this.state.filter, pageIndex: -1 });
  }
  hasExportAuthority() {
    return auth.hasExportAuthority();
  }
}
export default new PayedOrderRecordes();
