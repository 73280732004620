import React, { Component } from "react";
import { Table, Divider, message } from "antd";
import { Link } from "react-router-dom";
import { Subscribe } from "unstated";
import model from "./model";
import { getSites, getPaymentSetting } from "../../service";
import {
  PAYED_ORDER_STATUS,
  PAYED_ORDER_STATUS_FILTER,
  ORDER_STATUS,
  ORDER_STATUS_FILTER,
  CAR_FEE_TYPE,
  REFOUND_STATUS,
  VISITOR_HAS_REFOUND_PERMISSION,
  INVOICE_STATUS_FILTER
} from "../../constants";
import auth from "../../auth";

const TRIP_STATUS = {
  "1": "已受理",
  "2": "支付成功",
  "3": "支付失败",
  "4": "已退款"
};

const TRIP_STATUS_FILTER = Object.keys(TRIP_STATUS).map(key => ({
  value: key + "",
  text: TRIP_STATUS[key]
}));

export default class OrderRecordList extends Component {
  constructor() {
    super();
    this.state = {
      trading_channels: [],
      trading_manners: []
    };
  }
  componentDidMount() {
    const { stationId, date } = this.props.match.params;
    getPaymentSetting().then(({ trading_channels, trading_manners }) => {
      this.setState({
        trading_channels,
        trading_manners
      });
    });

    model
      .filter({
        stationId,
        accountDate: date,
        payTypeId: "",
        orderStatus: -1,
        channelId: ""
      })
      .then(() => {
        model.queryPayedOrders().catch(e => message.error(e.message));
      });
  }
  onChange({ current, pageSize }, filters, sorter) {
    const { paymentTypeName, paymentChannelName, orderStatus } = filters;

    model
      .filter({
        pageIndex: model.state.filter.pageIndex !== current ? current : 1,
        pageCount: pageSize,
        ...(paymentChannelName
          ? { channelId: paymentChannelName[0] || "" }
          : {}),
        ...(paymentTypeName ? { payTypeId: paymentTypeName[0] || "" } : {}),
        ...(orderStatus ? { orderStatus: orderStatus[0] || -1 } : {})
      })
      .then(() => {
        model.queryPayedOrders().catch(e => message.error(e.message));
      });
  }
  render() {
    const { scrollX } = this.props;
    const { trading_channels, trading_manners } = this.state;

    const getColumns = () =>
      [
        {
          title: "订单创建时间",
          dataIndex: "createTime",
          key: "createTime"
        },
        {
          title: "车道订单号",
          dataIndex: "laneOrderNumber",
          key: "laneOrderNumber"
        },
        {
          title: "腾路订单号",
          dataIndex: "orderNumber",
          key: "orderNumber"
        },
        {
          title: "腾路流水号",
          dataIndex: "flowNumber",
          key: "flowNumber"
        },
        {
          title: "渠道流水号",
          dataIndex: "channelNumber",
          key: "channelNumber"
        },
        // {
        //   title: "收费站点",
        //   dataIndex: "outSiteName",
        //   key: "outSiteName"
        // },
        {
          title: "应结订单金额(元)",
          dataIndex: "tripPayFee",
          key: "tripPayFee",
          render: v =>
            v !== undefined ? (
              <span className="money">{v ? (v / 100).toFixed(2) : 0}</span>
            ) : null
        },
        {
          title: "实际支付金额(元)",
          dataIndex: "flowPayAmount",
          key: "flowPayAmount",
          render: v =>
            v !== undefined ? (
              <span className="money">{v ? (v / 100).toFixed(2) : 0}</span>
            ) : null
        },
        {
          title: "车牌号",
          dataIndex: "cardNumber",
          key: "cardNumber"
        },
        {
          title: "车辆收费类型",
          dataIndex: "carFeeType",
          key: "carFeeType",
          render: v => CAR_FEE_TYPE[v] || ""
        },
        {
          title: "支付方式",
          dataIndex: "paymentTypeName",
          key: "paymentTypeName",
          filterMultiple: false,
          filters: trading_manners.length >= 2 ? trading_manners : [],
          filteredValue: model.state.filter.payTypeId
            ? [model.state.filter.payTypeId]
            : null
        },
        {
          title: "支付渠道",
          dataIndex: "paymentChannelName",
          key: "paymentChannelName",
          filterMultiple: false,
          filteredValue: model.state.filter.channelId
            ? [model.state.filter.channelId]
            : null,
          filters: trading_channels.length >= 2 ? trading_channels : []
        },
        {
          title: "支付状态/退款状态",
          dataIndex: "refundStatus",
          key: "refundStatus",
          render: (v, record) => {
            let pay = PAYED_ORDER_STATUS[record.payStatus] || "";
            let refound = REFOUND_STATUS[record.refundStatus] || "";
            return pay + (refound ? "/" + refound : "");
          }
        },
        {
          title: "订单状态",
          dataIndex: "orderStatus",
          key: "orderStatus",
          render: v => TRIP_STATUS[v] || "",
          filterMultiple: false,
          filteredValue:
            model.state.filter.orderStatus !== undefined
              ? model.state.filter.orderStatus == -1
                ? null
                : [model.state.filter.orderStatus]
              : null,
          filters: TRIP_STATUS_FILTER
        },

        {
          title: "车道编号",
          dataIndex: "laneNo",
          key: "laneNo"
        },

        {
          title: "备注",
          dataIndex: "remark",
          key: "remark"
        }
      ].map(c => {
        c.onCell = record => {
          if (record.children && record.children.length) {
            return {
              style: {
                color: "red"
              }
            };
          }
        };
        return c;
      });
    return (
      <Subscribe to={[model]}>
        {model => (
          <Table
            rowKey="id"
            pagination={{
              ...model.state.pagination,
              showSizeChanger: true,
              showQuickJumper: true,
              showTotal: total => `共${total}条数据`
            }}
            onChange={this.onChange.bind(this)}
            scroll={{ x: !!scrollX ? true : false }}
            className="list"
            columns={getColumns()}
            dataSource={model.state.list}
          />
        )}
      </Subscribe>
    );
  }
}
