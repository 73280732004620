import { ONLY_REAL_ADMIN } from "../../constants";
const menu = [
  {
    icon: "bars",
    text: "订单管理",
    children: [
      {
        url: "/orders/records",
        text: "支付订单"
      },
      {
        url: "/orders/refundment",
        text: "退款订单"
      }
    ]
  },
  {
    icon: "profile",
    text: "系统对账",
    children: [
      {
        url: "/chargeReports/records/batch",
        text: "班次报表"
      },
      {
        url: "/chargeReports/records/day",
        text: "日报表"
      },
      {
        url: "/chargeReports/records/month",
        text: "月度报表"
      },
      {
        url: "/chargeReports/records/year",
        text: "年度报表"
      }
    ]
  },
  {
    icon: "profile",
    text: "渠道对账",
    children: [
      {
        url: "/balance/records",
        text: "对账单"
      },
      {
        url: "/balance/Errors",
        text: "差错账"
      },
      {
        url: "/balance/Monthly",
        text: "月账单"
      }
    ]
  },

  {
    icon: "setting",
    text: "系统管理",
    auth: ONLY_REAL_ADMIN,
    children: [
      {
        url: "/users/records",
        text: "账号管理"
      },
      {
        url: "/logs/login",
        text: "日志管理"
      }
    ]
  }
];

export default menu;

export function getActiveMenuByPathname(pathname) {
  let active_sub_menu = [];
  let active_pat_menu = [];
  if (typeof pathname !== "string") return [];
  menu.map((pm, ip) => {
    (pm.children || []).map((sm, is) => {
      if (pathname.indexOf(sm.url) >= 0) {
        active_pat_menu.push(ip + "");
        active_sub_menu.push(ip + "_" + is);
      }
    });
  });

  return [active_pat_menu, active_sub_menu];
}
