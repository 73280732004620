import { Container } from 'unstated';
import { changePassword } from './service';
import encrypt from '../../libs/encrypt';
class Password extends Container {
  constructor(props) {
    super(props);
    this.initialState = {
      submitting: false,
      fields: {}
    }
    this.state = {
      ...this.initialState
    }
    
  }
  
  submit(values){
    this.setState({submitting: true})
    return changePassword({
      new_psw: encrypt(values.new_psw),
      old_psw: encrypt(values.old_psw)
    })
    .then(res => {
      this.setState({submitting: false})
      return res;
    })
    .catch(e => {
      this.setState({submitting: false})
      throw e;
    });
    
    return res
  };

  getDetail(form){
    return this.setState({
      fields: {...form}
    })
  }
    

  clearForm(){
    this.setState({
      ...this.initialState
    })
  };


}

export default new Password();