import request from "./libs/request";
import { cacheService } from "./libs/cache";
function _getUserInfo() {
  return request("/data/system/getUserInfo").then(res => res.data);
}

export const getUserInfo = cacheService(_getUserInfo);

function _getSites(merchant_id) {
  return request("/data/system/getAllTollStation", { id: merchant_id }).then(
    res => res.data
  );
}

const getSitesWithCache = cacheService(_getSites);

function getMySites(station_ids, sites) {
  let my_sites = [];
  let my_site_ids = (station_ids || "").split(",");
  my_site_ids = my_site_ids.map(id => +id);
  sites.map(site => {
    if (my_site_ids.includes(site.id)) {
      my_sites.push({ ...site });
    }
  });

  return my_sites;
}

export function getSites(showAllSites = false, merchantId) {
  return getUserInfo().then(user => {
    return getSitesWithCache(merchantId || user.merchant_id).then(sites => {
      return {
        user,
        sites:
          showAllSites || user.creater === 0
            ? sites
            : getMySites(user.station_ids, sites)
      };
    });
  });
}

function _getPaymentSetting() {
  return request("/data/trade/getPaymentSetting")
    .then(res => res.data)
    .then(data => {
      let trading_channels = [],
        trading_manners = [];
      trading_channels = data.map(d => ({
        text: d.pay_channel_name,
        value: d.pay_channel_id
      }));
      trading_manners = data.map(d => ({
        text: d.pay_type_name,
        value: d.pay_type_id
      }));
      let channel_keys = trading_channels.map(c => c.value);
      let manner_keys = trading_manners.map(m => m.value);
      for (let i = channel_keys.length - 1; i > 0; i--) {
        let value = trading_channels[i].value;
        if (channel_keys.indexOf(value) !== i) {
          trading_channels.splice(i, 1);
        }
      }
      for (let i = manner_keys.length - 1; i > 0; i--) {
        let value = trading_manners[i].value;
        if (manner_keys.indexOf(value) !== i) {
          trading_manners.splice(i, 1);
        }
      }

      let composeedManner = [];
      for(let item of trading_manners){
        let pushed = false;
        for(let sub of composeedManner){
          if (item.text === sub.text){
            sub.value = sub.value + "|" + item.value ;
            pushed = true;
          }
        }

        !pushed && composeedManner.push(item)
      }
      return {
        trading_channels,
        trading_manners: composeedManner
      };
    });
}

export const getPaymentSetting = cacheService(_getPaymentSetting);

export function logout() {
  return request("/data/system/logout").catch(console.log);
}

function _getMerchants() {
  return request("/data/system/queryMerchant").then(
    res => res.data
  );
}

const getMerchantsWithCache = cacheService(_getMerchants);

export function getMerchants() {
  return getUserInfo().then(user => {
    if (user.merchant_id) {
      return {
        user,
        merchants: [
          {
            id: user.merchant_id,
            name: user.merchant_name
          }
        ]
      };
    }
    return getMerchantsWithCache().then(merchants => {
      return {
        user,
        merchants
      };
    });
  });
}
