export const ROLE_OPERATER = 1;
export const ROLE_MONITER = 2;
export const ROLE_MASTER = 0;
export const USER_ROLE = {
  [ROLE_MASTER]: "商户管理员",
  [ROLE_OPERATER]: "收费管理员",
  [ROLE_MONITER]: "监控员"
};

export const PRIVIL_TYPE = {
  1: "交易数据"
};

export const PAYED_ORDER_STATUS = {
  3: "支付失败",
  4: "支付成功",
  6: "支付失败",
};

export const DRAWBACK_REASONS = {
  1: "重复收费",
  2: "实际扣款大于应支付费用",
  3: "未通行，车辆被套牌",
  4: "未通行，被扣款",
  5: "其他请输入"
};

export const TRADING_STATUS = {
  "-1": "全部",
  3: "交易失败",
  4: "交易成功",
  5: "撤销中",
  6: "撤销成功"
};

export const ERROR_TYPE = {
  0: "全部",
  1: "无差错",
  2: "差错缓存",
  3: "差错账"
};

export const HANDLE_STATUS = { "-1": "全部", 0: "未处理", 1: "已处理" };

export const REFOUND_STATUS = {
  1: "未退款",
  2: "退款中",
  3: "退款失败",
  4: "退款成功"
};

export const PLATE_COLOR = {
  1: "蓝色",
  2: "黄色",
  3: "白色",
  4: "黑色",
  5: "绿色"
};

export const CAR_FEE_TYPE = {
  0: "未知",
  1: "客一",
  2: "客二",
  3: "客三",
  4: "客四"
};

export const BALANCE_ERROR_TYPE = {
  0: "渠道漏单",
  1: "腾路漏单",
  2: "交易状态不符",
  3: "腾路短款",
  4: "腾路长款"
};

export const TRADING_TYPE = {
  1: "支付交易",
  2: "退款交易"
};

export const TRADING_TYPE_FILTER = Object.keys(TRADING_TYPE).map(key => ({
  value: key + "",
  text: TRADING_TYPE[key]
}));

export const TRADING_STATUS_FILTER = Object.keys(TRADING_STATUS)
  .map(key => ({
    value: key + "",
    text: TRADING_STATUS[key]
  }))
  .filter(item => item.value != "-1");

export const ERROR_TYPE_FILTER = Object.keys(ERROR_TYPE)
  .map(key => ({
    value: key + "",
    text: ERROR_TYPE[key]
  }))
  .filter(item => item.value != "0");

export const HANDLE_STATUS_FILTER = Object.keys(HANDLE_STATUS)
  .map(key => ({
    value: key + "",
    text: HANDLE_STATUS[key]
  }))
  .filter(item => item.value != "-1");

export const BALANCE_ERROR_TYPE_FILTER = Object.keys(BALANCE_ERROR_TYPE).map(
  key => ({
    value: key + "",
    text: BALANCE_ERROR_TYPE[key]
  })
);

export const PAYED_ORDER_STATUS_FILTER = Object.keys(PAYED_ORDER_STATUS).filter(k => k !== "6" /*过滤只显示一个支付失败选项*/).map(
  key => ({
    value: key + "",
    text: PAYED_ORDER_STATUS[key]
  })
);

export const ALL_VISITOR = 0;
export const ONLY_ADMIN = 1;
export const VISITOR_HAS_REFOUND_PERMISSION = 2;
export const VISITOR_HAS_MISACC_PERMISSION = 3;
export const VISITOR_HAS_DATA_PERMISSION = 4;
export const ONLY_REAL_ADMIN = 5;
export const VISITOR_HAS_CLASSES_PERMISSION = 6;

export const BALANCE_NO_ERROR = 0;
export const BALANCE_ACCOUNT_ERROR = 1;
export const BALANCE_CACHE_ERROR = 2;
export const BALANCE_ACCOUNT_AND_CACHE_ERROR = 3;

export const BALANCE_ACCOUNT_RESULT = {
  [BALANCE_NO_ERROR]: "无差错",
  [BALANCE_ACCOUNT_ERROR]: "差错账",
  [BALANCE_CACHE_ERROR]: "差错缓存",
  [BALANCE_ACCOUNT_AND_CACHE_ERROR]: "差错账;差错缓存"
};

export const TOLLER_SHIFT = {
  1: "早班",
  2: "中班",
  3: "晚班"
};

export const INVOICE_STATUS = {
  1: "已开票",
  2: "未开票"
};

export const INVOICE_STATUS_FILTER = Object.keys(INVOICE_STATUS).map(key => ({
  value: key + "",
  text: INVOICE_STATUS[key]
}));

export const ORDER_STATUS = {
  0: "已付款",
  1: "未付款",
  2: "已退款",
};

export const ORDER_STATUS_FILTER = Object.keys(ORDER_STATUS).map(key => ({
  value: (+key + 1) + "",
  text: ORDER_STATUS[key]
}));


