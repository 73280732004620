import request from '../../libs/request';
import { getUserInfo } from '../../service';
import moment from 'moment';
export function handleBalanceError(params) {
  return getUserInfo().then(user => {
    return request('/data/trade/handleMisAcc', { ...params, operator_id: user.name + '' })
    .then(res => ({ 
      id: params.id, 
      handlerSts: 1, 
      remark: params.handle_remark, 
      operater: user.name + '' ,
      handleTime: moment().format('YYYY-MM-DD HH:mm:ss')
    }))
  })

}
