import React, { Component } from 'react';
import { Modal, message } from 'antd';
import { Provider, Subscribe } from 'unstated';
import model from './model.js';
import Form from './form.js';
export default class TollerFormModal extends Component {
  constructor(props) {
    const { match: { params: { id } } } = props;
    super(props);
    this.title = id ? '编辑收费员' : '添加收费员';
  }
  handleCancel(e) {
    const { history } = this.props;
    history.goBack();
  }

  onSubmit(values) {
    const { match: { params: { id } }, history } = this.props;
    const word = this.title;
    model.writeToller(id ? { ...values, id } : values)
      .then(message.success.bind(message, word + "成功"))
      .then(history.goBack)
      .catch(e => {
        console.log(e);
        message.error(e.message || word + "失败")
      })
  }

  componentWillUnmount() {
    model.clearForm && model.clearForm();
  }

  render() {
    return (
      <Provider>
        <Subscribe to={[model]}>
          {model => (
            <Modal
              closable={model.state.submitting ? false : true}
              confirmLoading={model.state.submitting}
              maskClosable={false}
              title={this.title}
              visible={true}
              onOk={this.onSubmit.bind(this)}
              onCancel={this.handleCancel.bind(this)}
              footer={null}
            >
              <Form
                model={model}
                onCancel={this.handleCancel.bind(this)}
                onSubmit={this.onSubmit.bind(this)}
                location={this.props.location} />
            </Modal>)
          }
        </Subscribe>
      </Provider>
    )
  }
}