import React, { Component } from 'react';
import { Modal, message } from 'antd';
import { Provider, Subscribe } from 'unstated';
import model from './model.js';
import Form from './form.js';
import Users from '../users/model';

export default class UserForm extends Component {

  handleCancel(e) {
    const { match, history } = this.props;
    history.goBack();
  }

  onSubmit(values) {
    const { match: { params: id }, history } = this.props;

    let res = model.submit(values)
      .then(id ? Users.edit.bind(Users) : Users.add.bind(Users))
      .then(message.success.bind(message, id ? '账号编辑成功' : '账号新增成功'))
      .then(history.goBack)
      .catch(e => {
        console.log(e);
        message.error(e.message || (id ? '账号编辑失败' : '账号新增失败'))
      })
      .then(Users.queryUsers.bind(Users))

  }

  componentDidMount() {

  }

  componentWillUnmount() {

    model.clearForm && model.clearForm();
  }
  render() {
    const { match: { params: { id } } } = this.props
    
    return (
      <Provider>
        <Subscribe to={[model]}>
          {model => (
            <Modal
              closable={model.state.submitting ? false : true}
              confirmLoading={model.state.submitting}
              maskClosable={false}
              title={id ? "编辑账号" : "新增账号"}
              visible={true}
              onOk={this.onSubmit.bind(this)}
              onCancel={this.handleCancel.bind(this)}
              footer={null}
            >
              <Form model={model} onCancel={this.handleCancel.bind(this)} onSubmit={this.onSubmit.bind(this)} location={this.props.location} />
            </Modal>)
          }
        </Subscribe>
      </Provider>
    )
  }
}