import Collection from '../../libs/collection';
import { getErrorBalanceRecords , exportBalanceErrors} from './service'
import auth from '../../auth';
class BalanceErrorRecords extends Collection {
  getErrorBalanceRecords(){
   return getErrorBalanceRecords(this.state.filter)
    .then(res => {
      this.list(res.data, res.pagination)
    })
  }
  exportBalanceErrors(params){
    return exportBalanceErrors({...this.state.filter, ...params})
  }
  hasExportAuthority(){
    return  auth.hasExportAuthority();
  }
}
export default new BalanceErrorRecords()