import React, { Component } from 'react';
import { Form, Button, Input } from 'antd';
import { SiteSelector } from '../../components';
import schema from './schema';

const Field = Form.Item;

class PureForm extends Component {

  onSubmit(e) {
    const { onSubmit, form } = this.props;
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        onSubmit && onSubmit(values)
      }
    });
  }

  onCancel() {
    this.props.onCancel && this.props.onCancel()
  }

  componentDidMount() {
    const { form, location } = this.props;
    const fields = location.state.fields;
    if (fields) {
      let values = {};
      for(let key in schema){
        values[key] = fields[key]
      }
      form.setFieldsValue(values)
    }

  }

  render() {
    const { model, form } = this.props;
    const { getFieldDecorator } = form;
    const { location } = this.props;
    const id = location.state && location.state.fields ? location.state.fields.id : null;

    return (
      <Form className="modal-form" onSubmit={this.onSubmit.bind(this)}>
        <Field label="姓名">{getFieldDecorator('name', { rules: schema.name.rules })(<Input />)}</Field>
        <Field label="工号">{getFieldDecorator('casher_number', { rules: schema.casher_number.rules })(<Input disabled={!!id} />)}</Field>
        <Field label="手机号">{getFieldDecorator('phone', {initialValue: '', rules: schema.phone.rules })(<Input />)}</Field>
        <Field label="所属站点">{getFieldDecorator('toll_station_id', { rules: schema.toll_station_id.rules })(<SiteSelector
          showAllSites={true}
          notSelectFirst={true}
          placeholder="请选择" />)}</Field>

        <div className="modal-form-footer">
          <Button className="modal-form-cancel" onClick={this.onCancel.bind(this)} disabled={model.state.submitting}>取消</Button>
          <Button className="modal-form-submit" type="primary" htmlType="submit" loading={model.state.submitting}>确定</Button>
        </div>
      </Form>
    )
  }
}

const WrapedPureForm = Form.create()(PureForm);

export default WrapedPureForm;


