import React, { Component } from "react";
import { Menu, Icon } from "antd";
import auth from "../../auth";
import { Link } from "react-router-dom";
const { SubMenu } = Menu;
import menu, { getActiveMenuByPathname } from "./menu";
export default class SideMenu extends Component {
  constructor() {
    super();
    this.state = {
      user: null
    };
  }
  componentDidMount() {
    auth.getUserInfo().then(user => this.setState({ user }));
  }

  generateMenu() {
    return menu.map(
      (mp, im) =>
        auth.hasAuthority(mp.auth) ? (
          <SubMenu
            key={im}
            title={
              <span>
                <Icon type={mp.icon} />
                {mp.text}
              </span>
            }
          >
            {(mp.children || []).map((ms, is) => (
              <Menu.Item key={im + "_" + is}>
                <Link to={ms.url}>{ms.text}</Link>
              </Menu.Item>
            ))}
          </SubMenu>
        ) : null
    );
  }

  render() {
    const {
      location: { pathname }
    } = this.props;
    const [active_pat_menu, active_sub_menu] = getActiveMenuByPathname(
      pathname
    );
    return (
      <Menu
        theme="dark"
        mode="inline"
        defaultSelectedKeys={active_sub_menu}
        defaultOpenKeys={active_pat_menu}
        style={{ height: "100%", borderRight: 0 }}
      >
        {this.generateMenu()}
      </Menu>
    );
  }
}
