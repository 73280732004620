import React, { Component } from 'react';
import { Card, Button, Radio } from 'antd';
import { Link } from 'react-router-dom';
import Search from './search';
import List from './list';
import { Provider } from 'unstated';
import "./index.less";
export default class BalanceMonthly extends Component {
  render() {
    return (
      <Provider>
        <div className="page-content BalanceMonthly">

          <Card className="header">
            <div className="title">月账单</div>
          </Card>

          <Card className="content">
            <Search></Search>
            <List scrollX={true} />
            <div>注：</div>
            <div>实收总金额=支付实收金额-退款金额</div>
            <div>结算金额=实收总金额-手续费</div>
          </Card>



        </div>
      </Provider>
    )
  }
}
