import React, { Component } from 'react';
import { Form, Button, Input, message } from 'antd';
import model from './model';
const Field = Form.Item;
class UserSearch extends Component {
  onSubmit(e) {
    const { form } = this.props;
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        model.filter({
          pageIndex: 1,
          condition: values.condition || '',
        }).then(() => {
          model.queryUsers()
          .catch(e => message.error(e.message))
        })
      }
    });
    
  }

  onReset() {
    const { resetFields	} = this.props.form;
    resetFields();
    model.filter({
      condition: '',
      pageIndex: 1,
      pageCount: 10,
    }).then(() => {
      model.queryUsers()
      .catch(e => message.error(e.message))
    })
  }
  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form layout="inline" className="search" onSubmit={this.onSubmit.bind(this)}>
        <Field label="关键词">
          {
            getFieldDecorator('condition')(<Input placeholder="账号/姓名/手机号"></Input>)
          }</Field>
        <Button type="primary" htmlType="submit">查询</Button>
        <Button htmlType="button" onClick={this.onReset.bind(this)}>重置</Button>
      </Form>
    )
  }
}

export default Form.create()(UserSearch)