import request from "../../libs/request";
import exportFile from "../../libs/exportFile";
import { getUserInfo } from "../../service";

export function fetchRefoundTickets(params) {
  return getUserInfo().then(user =>
    request("/data/stat/listRefund", {
      ...params,
      merchantId: user.merchant_id
    })
  );
}
export function exportRefoundTickets(params) {
  return getUserInfo().then(user =>
    exportFile("/data/stat/exportRefund", {
      ...params,
      merchantId: user.merchant_id
    })
  );
}

export function removeRefoundTicket(id) {
  return getUserInfo().then(user =>
    request("/data/stat/deleteRefund", { id, merchantId: user.merchant_id })
  );
}
