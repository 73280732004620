import React, { Component } from "react";
import { Form, Button, Input, Select, Alert } from "antd";
import { Provider, Subscribe, Container } from "unstated";
import { DRAWBACK_REASONS } from "../../constants";
const Field = Form.Item;
class PureForm extends Component {
  onSubmit(e) {
    const {
      onSubmit,
      form,
      location: {
        state: { fields }
      }
    } = this.props;
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        let data = {
          flow_no: fields.flowNo,
          refund_fee: values.payFee,
          refund_desc: values.remark || DRAWBACK_REASONS[values.reason]
        };
        
        onSubmit && onSubmit(data);
      }
    });
  }

  onCancel() {
    this.props.onCancel && this.props.onCancel();
  }

  render() {
    const {
      model,
      form,
      location: {
        state: { fields }
      }
    } = this.props;
    const { getFieldDecorator, getFieldValue } = this.props.form;

    const FeeTip = `实际支付${
      fields.payFee ? (fields.payFee / 100).toFixed(2) : 0
    }元，最多可退${fields.payFee ? (fields.payFee / 100).toFixed(2) : 0}元`;
    const FeeHelpError = field => {
      const error = this.props.form.getFieldError(field);
      if (error && error.length) return error[0];
      return FeeTip;
    };

    const HasFeeError = field => {
      const error = this.props.form.getFieldError(field);
      if (error && error.length) return true;
      return false;
    };
    return (
      <Form className="modal-form" onSubmit={this.onSubmit.bind(this)}>
        <Alert
          message={`正在发起车辆【${fields.cardNo}】在【${
            fields.createTime
          }】的退款`}
          type="info"
          showIcon
        />
        <Field label="订单支付时间">
          <span className="text">{fields.createTime}</span>
        </Field>
        <Field label="车道订单号">
          <span className="text">{fields.feeSystemTradeNo}</span>
        </Field>
        <Field label="渠道流水号">
          <span className="text">{fields.otherTrxNo}</span>
        </Field>
        <Field
          label="退款金额"
          validateStatus={HasFeeError("payFee") ? "error" : ""}
          help={FeeHelpError("payFee")}
        >
          {/* <span className="text">
           {fields.payFee ? (fields.payFee / 100).toFixed(2) : 0} 
            </span>*/}
          {getFieldDecorator("payFee", {
            rules: [
              { required: true, message: "请输入退款金额" },
              {
                validator: (rule, value, callback) => {
                  if (+value > 0 && +value <= +fields.payFee / 100) {
                    return callback();
                  }
                  callback(FeeTip);
                }
              }
            ]
          })(<Input placeholder="请输入" />)}
        </Field>
        <Field label="退款原因">
          {getFieldDecorator("reason", {
            rules: [{ required: true, message: "请选择退款原因" }]
          })(
            <Select placeholder="请选择">
              {Object.keys(DRAWBACK_REASONS).map(d => (
                <Select.Option key={+d} value={+d}>
                  {DRAWBACK_REASONS[d]}
                </Select.Option>
              ))}
            </Select>
          )}
        </Field>
        {getFieldValue("reason") == 5 ? (
          <Field label=" ">
            {getFieldDecorator("remark", {
              rules: [
                { required: true, message: "请输入退款原因" },
                { min: 2, max: 100, message: "长度应为2-100个字符" }
              ]
            })(<Input.TextArea rows={2} style={{ marginBottom: "15px" }} />)}
          </Field>
        ) : null}

        <div className="modal-form-footer">
          <Button
            className="modal-form-cancel"
            onClick={this.onCancel.bind(this)}
            disabled={model.state.submitting}
          >
            取消
          </Button>
          <Button
            className="modal-form-submit"
            type="primary"
            htmlType="submit"
            loading={model.state.submitting}
          >
            确定
          </Button>
        </div>
      </Form>
    );
  }
}

const WrapedPureForm = Form.create()(PureForm);

export default WrapedPureForm;
