import React, { Component } from 'react';
import { Card, Button, Radio } from 'antd';
import { Link } from 'react-router-dom';
import Search from './search';
import List from './list';
import { Provider } from 'unstated'
export default class BalanceErrors extends Component {
  render() {
    return (
      <Provider>
        <div className="page-content BalanceErrors">

          <Card className="header">
            <div className="title">对账管理</div>
            <div className="extra">
              <Radio.Group value="2">
                <Radio.Button value="1"><Link to="/balance/records">对账单</Link></Radio.Button>
                <Radio.Button value="2">差错账</Radio.Button>
              </Radio.Group>
            </div>
          </Card>

          <Card className="content">
            <Search></Search>
            
            <List scrollX={true} location={this.props.location}/>
          </Card>



        </div>
      </Provider>
    )
  }
}
