import React, { Component } from "react";
import { Form, Button, Input, Select, message } from "antd";
const Field = Form.Item;
import model from "./model";
import { SiteSelector, DateTimeRangePicker } from "../../components";
import moment from "moment";
class OrderRecordSearch extends Component {
  onSubmit(e) {
    const { form } = this.props;
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        let [begin_time, end_time] = values.date;
        let filter = {
          pageIndex: 1
        };
        begin_time
          ? (filter.begin_time = moment(begin_time).format(
              "YYYY-MM-DD HH:mm:ss"
            ))
          : null;
        end_time
          ? (filter.end_time = moment(end_time).format("YYYY-MM-DD HH:mm:ss"))
          : null;
        values.condition && values.condition.trim()
          ? (filter.condition = values.condition)
          : null;
        filter.toll_station_id = values.toll_station_id;
        model.filter(filter).then(() => {
          model.queryPayedOrders().catch(e => message.error(e.message));
        });
      }
    });
  }

  onReset() {
    const { resetFields } = this.props.form;
    resetFields(["condition", "date"]);
    model
      .filter({
        begin_time: "",
        end_time: "",
        condition: "",
        pageIndex: 1,
        pageCount: 10,
        pay_type_id: "",
        channel_id: "",
        pay_status: 0,
        order_status: 0,
        fp_qqlsh: 0,
      })
      .then(() => {
        model.queryPayedOrders().catch(e => message.error(e.message));
      });
  }
  onStationChange(toll_station_id) {
    model
      .filter({
        toll_station_id
      })
      .then(() => {
        model.queryPayedOrders().catch(e => message.error(e.message));
      });
  }

  componentDidMount() {
    model.filter({
      begin_time: "",
      end_time: "",
      condition: "",
      pageIndex: 1,
      channel_id: "",
      pay_type_id: "",
      pay_status: 0,
      order_status: 0,
      pageCount: 10,
      fp_qqlsh: 0,
    });
  }
  render() {
    const { getFieldDecorator } = this.props.form;
    const date_options = {
      initialValue: [null, null]
    };

    return (
      <Form
        layout="inline"
        className="search"
        onSubmit={this.onSubmit.bind(this)}
      >
        <div style={{ marginBottom: "10px" }}>
          <Field label="&emsp;&emsp;收费站点">
            {getFieldDecorator("toll_station_id")(
              <SiteSelector onChange={this.onStationChange.bind(this)} />
            )}
          </Field>
        </div>
        <Field label="订单创建时间">
          {getFieldDecorator("date", date_options)(<DateTimeRangePicker />)}
        </Field>
        <Field label="关键词">
          {getFieldDecorator("condition")(
            <Input placeholder="车牌号/订单号" />
          )}
        </Field>
        <Button type="primary" htmlType="submit">
          查询
        </Button>
        <Button htmlType="button" onClick={() => this.onReset()}>
          重置
        </Button>
        {/* {model.hasExportAuthority() ? (
          <Button
            className="export"
            onClick={() => model.exportPayedOrderRecords()}
          >
            导出数据
          </Button>
        ) : null} */}
      </Form>
    );
  }
}

export default Form.create()(OrderRecordSearch);
