import React, { Component } from 'react';
import { Provider, Subscribe } from 'unstated';
import './login.less';
import model from './model';
import LoginForm from './form';
import auth from '../../auth';
import { message } from 'antd'
import cdn from '../../libs/cdn';
class NormalLoginForm extends Component {
  onSubmit(values) {
    const { match: { params: id }, history } = this.props;
    let res = model.submit(values)
    .then(info => {
      auth.authenticate(info);
      history.push('/')
    })
    .catch(e => {
      message.error(e && e.message ? e.message : '登录失败');
      window.$$captchaObj && window.$$captchaObj.reset();
    })
  }

  componentWillUnmount() {
    model.clearForm && model.clearForm();
  }
  render() {
    return (
      <Provider>
        <Subscribe to={[model]}>
        {model=> (
          <div className="login-page">
            <div className="header">
              <img src={cdn('logo_login.png')} className="logo" />
              <span className="plate-name">智能化收费平台</span>
            </div>
            <div className="main">
              <div className="banner">
                <img src={cdn('banner.png')} />
              </div>
              <LoginForm model={model} onSubmit={this.onSubmit.bind(this)} />
            </div>
            <div className="copyright">Copyright 2018 武汉腾路智行</div>
          </div>
        )}
        </Subscribe>
      </Provider>
    );
  }
}

export default NormalLoginForm;








