import request from '../../libs/request';
export function queryUsers(params) {
  return request('/data/system/queryAccount', params)
}

export function toggleUserFrizeStatus(id, type) {
  return request('/data/system/operateAccount', { id, type }).then(res => res.data)
}

export function resetUserPassword(id) {
  return request('/data/system/resetAccountPsw', { id }).then(res => res.data)
}
