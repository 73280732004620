import request from "../../libs/request";
import exportFile from "../../libs/exportFile";
import { getUserInfo } from "../../service";
import uuid from "../../libs/uuid";
import moment from 'moment';

export function fetchChargeReportsOfBatch(params) {
  return getUserInfo().then(user =>
    request("/data/stat/listShiftRecord", {
      ...params,
      merchantId: user.merchant_id
    }).then(res => ({
      ...res,
      data: res.data.map((item) => ({
        id: uuid(),
        ...item,
      }))
    }))
  );
}

export function fetchChargeReportsOfDay(params) {
  return getUserInfo().then(user =>
    request("/data/stat/listDailyReport", {
      ...params,
      merchantId: user.merchant_id
    }).then(res => ({
      ...res,
      data: res.data.map((item) => ({
        id: uuid(),
        ...item,
        sealBillDate: item.dailyDate,
      }))
    }))
  );
}

export function fetchChargeReportsOfMonth(params) {
  return getUserInfo().then(user =>
    request("/data/stat/listMonthlyReport", {
      ...params,
      startDate: moment(params.startDate).startOf("month").format('YYYY-MM-DD'),
      endDate: moment(params.endDate).endOf("month").format('YYYY-MM-DD'),
      merchantId: user.merchant_id
    }).then(res => ({
      ...res,
      data: res.data.map((item) => ({
        id: uuid(),
        ...item,
        sealBillDate: item.monthlyDate,
      }))
    }))
  );
}

export function fetchChargeReportsOfYear(params) {
  return getUserInfo().then(user =>
    request("/data/stat/listYearReport", {
      ...params,
      startDate: moment(params.startDate).startOf("month").format('YYYY-MM-DD'),
      endDate: moment(params.endDate).endOf("month").format('YYYY-MM-DD'),
      merchantId: user.merchant_id
    }).then(res => ({
      ...res,
      data: res.data.map((item) => ({
        id: uuid(),
        ...item,
        sealBillDate: item.monthlyDate,
      }))
    }))
  );
}

export function exportChargeReportsOfBatch(params) {
  return getUserInfo().then(user =>
    exportFile("/data/stat/exportTollShiftReport", {
      stationId: params.stationId,
      accountDate: params.accountDate,
      merchantId: user.merchant_id,
      classesType: params.classesType,
    })
  );
}

export function exportChargeReportsOfDay(params) {
  return getUserInfo().then(user =>
    exportFile("/data/stat/exportDailyReport", {
      stationId: params.stationId,
      accountDate: params.accountDate,
      merchantId: user.merchant_id
    })
  );
}

export function exportChargeReportsOfMonth(params) {
  return getUserInfo().then(user =>
    exportFile("/data/stat/exportMonthlyReport", {
      stationId: params.stationId,
      accountDate: params.accountDate,
      merchantId: user.merchant_id
    })
  );
}


export function exportChargeReportsOfYear(params) {
  return getUserInfo().then(user =>
    exportFile("/data/stat/exportYearReport", {
      stationId: params.stationId,
      accountDate: params.accountDate,
      merchantId: user.merchant_id
    })
  );
}