import Collection from "../../libs/collection";
import {
  fetchRefoundTickets,
  exportRefoundTickets,
  removeRefoundTicket
} from "./service";
import auth from "../../auth";
import moment from "moment";
class RefoundTickets extends Collection {
  constructor(props) {
    super(props);
    this.initialFilter = {
      startDate: "",
      endDate: "",
      employeeNo: "",
      pageIndex: 1,
      pageCount: 10
    };
    this.state = {
      ...this.state,
      filter: { ...this.initialFilter }
    };
  }

  fetchRefoundTickets() {
    return fetchRefoundTickets(this.state.filter).then(res => {
      this.list(res.data, res.pagination);
    });
  }

  queryRefoundTickets(query) {
    let [startDate, endDate] = query.date || [];
    delete query.date;
    let params = {
      ...query,
      startDate: startDate
        ? moment(startDate).format("YYYY-MM-DD")
        : this.state.startDate || "",
      endDate: endDate
        ? moment(endDate).format("YYYY-MM-DD")
        : this.state.endDate || ""
    };
    return this.filter({
      ...params,
      pageIndex: 1
    }).then(() => this.fetchRefoundTickets());
  }

  filterRefoundTickets(params) {
    return this.filter({
      ...params
    }).then(() => this.fetchRefoundTickets());
  }

  resetRefoundTicketsQueryParamsAndFetch() {
    return this.filter({
      ...this.initialFilter
    }).then(() => this.fetchRefoundTickets());
  }

  exportRefoundTickets(params = { pageIndex: -1 }) {
    return exportRefoundTickets({ ...this.state.filter, ...params });
  }

  removeRefoundTicket(item) {
    return removeRefoundTicket(item.id).then(() => this.remove(item));
  }

  hasExportAuthority() {
    return auth.hasExportAuthority();
  }
}
export default new RefoundTickets();
