import request from "../../libs/request";
import exportFile from "../../libs/exportFile";
import { getUserInfo } from "../../service";
import uuid from "../../libs/uuid";
import omit from "omit.js";
const plainToTree = data => {
  let rest = [];
  for (let item of data) {
    let has = false;
    for (let sub of rest) {
      if (sub.orderNumber === item.orderNumber) {
        has = true;
        sub.children = sub.children || [];
        sub.children.push(omit(item,[
          'createTime',
          'laneOrderNumber',
          'orderNumber',
          'tripPayFee',
          'cardNumber',
          'carFeeType',
          'orderStatus',
          'laneNo',
        ]));
      }
    }
    if (!has) {
      rest.push(item);
    }
  }

  return rest;
};

const sortFormatedData = list => {
  return list.sort((b,a) => {
    if ((!a.children || !a.children.length) && (b.children && b.children.length)){
      return -1;
    }
    return 0;
  })
} 

export function queryPayedOrders(params) {
  return getUserInfo().then(user =>
    request("/data/stat/getDailyOrderDetail", {
      ...params,
      merchantId: user.merchant_id
    }).then(res => ({
      ...res,
      data: sortFormatedData(plainToTree(
        res.data.map(item => ({
          id: uuid(),
          ...item,
          remark: [item.summaryRemark || "", item.remark || ""].filter(d => !!d).join(" / ")
        }))
      ))
    }))
  );
}
export function exportPayedOrderRecords(params) {
  return exportFile("/data/trade/exportPayTrade", params);
}
