import Collection from '../../libs/collection';
import { getBalanceRecords, exportBalanceRecords } from './service'
import auth from '../../auth';
class BalanceRecordes extends Collection {

  getBalanceRecords() {
    return getBalanceRecords(this.state.filter)
      .then(res => {
        this.list(res.data, res.pagination)
      })
  }
  exportBalanceRecords(params) {
    return exportBalanceRecords({ ...this.state.filter , ...params});
  }
  hasExportAuthority(){
    return  auth.hasExportAuthority();
  }
}
export default new BalanceRecordes()