import Collection from "../../libs/collection";
import {
  fetchChargeReportsDetailOfBatch,
  fetchChargeReportsDetailOfDay,
  fetchChargeReportsDetailOfMonth,
  fetchChargeReportsDetailOfYear,
  postPrintLog
} from "./service";
import { VISITOR_HAS_CLASSES_PERMISSION } from "../../constants";
import auth from "../../auth";
class ChargeReportsDetail extends Collection {
  constructor(props) {
    super(props);
    this.initialFilter = {
      stationId: -1,
      startDate: "",
      endDate: "",
      pageIndex: 1,
      pageCount: 10
    };
    this.state = {
      ...this.state,
      filter: { ...this.initialFilter },
      reportType: "batch"
    };
  }

  fetchChargeReportsDetail() {
    const { reportType } = this.state;
    let fetchFn = {
      batch: fetchChargeReportsDetailOfBatch,
      day: fetchChargeReportsDetailOfDay,
      month: fetchChargeReportsDetailOfMonth,
      year: fetchChargeReportsDetailOfYear
    }[reportType];

    return fetchFn(this.state.filter).then(res => {
      this.list(res.data, res.pagination);
    });
  }

  queryChargeReportsDetail(params) {
    return this.filter({
      ...params,
      pageIndex: 1
    }).then(() => this.fetchChargeReportsDetail());
  }

  filterChargeReportsDetail(params) {
    return this.filter({
      ...params
    }).then(() => this.fetchChargeReportsDetail());
  }

  resetChargeReportsDetailQueryParamsAndFetch() {
    return this.filter({
      ...this.initialFilter
    }).then(() => this.fetchChargeReportsDetail());
  }

  hasPrintAuthority() {
    return auth.hasAuthority(VISITOR_HAS_CLASSES_PERMISSION);
  }

  writePrintLog(params) {
    return postPrintLog({
      ...this.state.filter,
      reportType: this.state.reportType,
      tollerShift: params.id,
    });
  }
}
export default new ChargeReportsDetail();
