import React, { Component } from "react";
import { Form, Button, Input, message, Select } from "antd";
const Field = Form.Item;
import model from "./model";
import { DateTimeRangePicker, MonthRangePicker } from "../../components";
import moment from "moment";
import { getMerchants, getSites } from "../../service";
class ChargeReportsSearch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      merchants: [],
      sites: [],
      merchantId: undefined,
      stationId: undefined,
      defaultDate: [
        moment()
          .subtract(1, "months")
          .startOf("month"),
        moment().endOf("month")
      ],
      date: [
        moment()
          .subtract(1, "months")
          .startOf("month"),
        moment().endOf("month")
      ]
    };
  }
  async componentDidMount() {
    const { type } = this.props.match.params;
    try {
      const { merchants } = await getMerchants();
      const { sites } = await getSites(merchants[0].id);
      const { date } = this.state;
      const merchantId = merchants[0].id;
      const stationId = sites[0].id;
      this.setState({
        merchants,
        sites,
        merchantId,
        stationId
      });
      model
        .queryChargeReports({ merchantId, stationId, date }, type)
        .catch(e => message.error(e.message));
    } catch (e) {
      message.error(e.message || "获取查询条件失败");
    }
  }
  onSubmit(e) {
    const { form } = this.props;
    const { merchantId, stationId, date } = this.state;
    const { type } = this.props.match.params;
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        model
          .queryChargeReports(
            {
              date,
              merchantId,
              stationId
            },
            type
          )
          .catch(e => message.error(e.message));
      }
    });
  }

  onReset() {
    const { type } = this.props.match.params;
    const { defaultValue } = this.state;
    model
      .queryChargeReports(
        {
          date: defaultValue
        },
        type
      )
      .catch(e => message.error(e.message));
  }

  onStationChange(stationId) {
    const { type } = this.props.match.params;
    const { merchantId, date } = this.state;
    model.setState({
      reportType: type,
      stationId
    });
    this.setState({
      stationId
    });
    model
      .queryChargeReports({ merchantId, stationId, date }, type)
      .catch(e => message.error(e.message));
  }
  onMerchantChange(merchantId) {
    const { type } = this.props.match.params;
    const { stationId, date } = this.state;
    model.setState({
      reportType: type
    });
    this.setState({
      merchantId
    });
    model
      .queryChargeReports({ merchantId, stationId, date }, type)
      .catch(e => message.error(e.message));
  }

  onDateChange(date) {
    this.setState({
      date
    });
  }
  render() {
    const { type } = this.props.match.params;

    const { merchants, sites, merchantId, stationId, date } = this.state;
    return (
      <Form
        layout="inline"
        className="search"
        onSubmit={this.onSubmit.bind(this)}
      >
        <Field label="商户简称">
          <Select
            showArrow={merchants.length > 1}
            onChange={this.onMerchantChange.bind(this)}
            value={merchantId}
          >
            {merchants.map(s => (
              <Select.Option key={s.id} value={s.id}>
                {s.name}
              </Select.Option>
            ))}
          </Select>
        </Field>

        <Field label="收费站点">
          <Select
            showArrow={sites.length > 1}
            onChange={this.onStationChange.bind(this)}
            value={stationId}
          >
            {sites.map(s => (
              <Select.Option key={s.id} value={s.id}>
                {s.name}
              </Select.Option>
            ))}
          </Select>
        </Field>

        <Field label="选择日期">
          {type === "month" || type === "year" ? (
            <MonthRangePicker
              value={date}
              onChange={this.onDateChange.bind(this)}
            />
          ) : (
            <DateTimeRangePicker
              format="YYYY-MM-DD"
              rest={{
                showTime: false,
                allowClear: false
              }}
              value={date}
              onChange={this.onDateChange.bind(this)}
            />
          )}
        </Field>

        <Button type="primary" htmlType="submit">
          查询
        </Button>
        {/* <Button htmlType="button" onClick={() => this.onReset()}>
          重置
        </Button> */}
      </Form>
    );
  }
}

export default Form.create()(ChargeReportsSearch);
