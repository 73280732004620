import React, { Component } from "react";
import { Card, Button } from "antd";
import Search from "./search";
import List from "./list";
import { Provider, Subscribe } from "unstated";
import model from "./model.js";
import "./index.less";
export default class BalanceDayRecords extends Component {
  render() {
    return (
      <Provider>
        <div className="page-content BalanceDayRecords">
          <Card className="header">
            <Button
              icon="left"
              className="goback"
              onClick={this.props.history.goBack}
            >
              返回
            </Button>
            <div className="title">对账管理日详情</div>
          </Card>

          <Card className="content">
            <Search location={this.props.location} />
            <Subscribe to={[model]}>
              {model => {
                const {
                  total_fee = 0,
                  total_pay = 0,
                  sum_money = 0,
                  pay_channel_summary = []
                } = model.state.summary || {};
                return (
                  <div className="analysis">
                    <div className="analysis-item">
                      <span>交易总笔数：</span>
                      <span>{total_pay || 0} (笔)</span>
                    </div>
                    {pay_channel_summary.map(item => (
                      <div className="analysis-item">
                        <span>{item.channel_name}笔数：</span>
                        <span>{item.total_pay || 0} (笔)</span>
                      </div>
                    ))}
                    <div className="analysis-item">
                      <span>交易金额：</span>
                      <span>
                        {sum_money ? (sum_money / 100).toFixed(2) : 0} (元)
                      </span>
                    </div>
                    {pay_channel_summary.map(item => (
                      <div className="analysis-item">
                        <span>{item.channel_name}金额：</span>
                        <span>
                          {item.sum_money
                            ? (item.sum_money / 100).toFixed(2)
                            : 0}{" "}
                          (元)
                        </span>
                      </div>
                    ))}

                    <div className="analysis-item">
                      <span>手续费：</span>
                      <span>
                        {total_fee ? (total_fee / 100).toFixed(2) : 0} (元)
                      </span>
                    </div>
                    {pay_channel_summary.map(item => (
                      <div className="analysis-item">
                        <span>{item.channel_name}手续费：</span>
                        <span>
                          {item.total_fee
                            ? (item.total_fee / 100).toFixed(2)
                            : 0}{" "}
                          (元)
                        </span>
                      </div>
                    ))}
                    <div className="analysis-item">
                      <span>结算金额：</span>
                      <span>
                        {sum_money + total_fee
                          ? ((sum_money + total_fee) / 100).toFixed(2)
                          : 0}{" "}
                        (元)
                      </span>
                    </div>
                    {pay_channel_summary.map(item => (
                      <div className="analysis-item">
                        <span>{item.channel_name}结算金额：</span>
                        <span>
                          {item.sum_money + item.total_fee
                            ? ((item.sum_money + item.total_fee) / 100).toFixed(
                                2
                              )
                            : 0}{" "}
                          (元)
                        </span>
                      </div>
                    ))}
                  </div>
                );
              }}
            </Subscribe>
            <List scrollX={true} location={this.props.location} />
          </Card>
        </div>
      </Provider>
    );
  }
}
