import { setCookie, delCookie } from "./libs/cookie";
import { getUserInfo } from "./service";
import { clearCache } from "./libs/cache";
import app from "./app";
import {
  ALL_VISITOR,
  ONLY_ADMIN,
  VISITOR_HAS_REFOUND_PERMISSION,
  VISITOR_HAS_MISACC_PERMISSION,
  VISITOR_HAS_DATA_PERMISSION,
  ONLY_REAL_ADMIN,
  VISITOR_HAS_CLASSES_PERMISSION
} from "./constants";
class Auth {
  constructor() {
    this.isAuthenticated = false;
    this.user = null;
  }

  authenticate(info, cb) {
    this.isAuthenticated = true;
    this.user = info.data;
    setCookie("dpgate_token", info.cookie);
    cb && cb();
  }

  signout(cb) {
    this.isAuthenticated = false;
    delCookie("dpgate_token");
    clearCache();
    setTimeout(cb, 100);
  }

  canRoute(url) {
    if (!this.isAuthenticated) return false;
    let routes = app.getAllRoutes();
    let links = Object.keys(routes);
    for (let link of links) {
      if (app.isRouteMatch(url, link)) {
        let route = routes[link];
        if (route.permission === undefined) {
          return true;
        }

        return this.hasAuthority(route.permission);
      }
    }

    return true;
  }

  hasAuthority(privil) {
    if (!this.isAuthenticated) return false;
    let user = this.user || {};
    let auth = true;
    switch (privil) {
      case ALL_VISITOR:
        auth = true;
        break;
      case ONLY_ADMIN:
        auth = user.creater === 0;
        break;
      case ONLY_REAL_ADMIN:
        auth = user.creater === 0 && user.user_type === 1;
        break;
      case VISITOR_HAS_REFOUND_PERMISSION:
        auth = !!user.refund_privil;
        break;
      case VISITOR_HAS_MISACC_PERMISSION:
        auth = !!user.misacc_privil;
        break;
      case VISITOR_HAS_DATA_PERMISSION:
        auth = !!user.data_privil;
        break;
      case VISITOR_HAS_CLASSES_PERMISSION:
        auth = !!user.classes_privil;
        break;
      default:
        auth = true;
    }

    return auth;
  }

  getUserInfo() {
    return getUserInfo().then(user => {
      this.isAuthenticated = true;
      this.user = user;
      return user;
    });
  }

  hasExportAuthority() {
    return this.user && !!this.user.privil_type;
  }
}

export default new Auth();
