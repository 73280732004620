import { Container } from 'unstated';
class UserInfo extends Container {
  constructor(props) {
    super(props);
    this.initialState = {
      submitting: false,
      fields: {}
    }
    this.state = {
      ...this.initialState
    }
    
  }

  getDetail(form){
    return this.setState({
      fields: {...form}
    })
  }
    

  clearForm(){
    this.setState({
      ...this.initialState
    })
  };


}

export default new UserInfo();