import React, { Component } from "react";
import { Card, Button, Radio } from "antd";
import { Link } from "react-router-dom";
import List from "./list";
import { Provider } from "unstated";
import "./index.less";
export default class OrderRecords extends Component {
  componentDidMount() {}
  render() {
    const { history } = this.props;
    const { date } = this.props.match.params;
    return (
      <Provider>
        <div className="page-content OrderRecords">
          <Card className="header">
            <Button
              onClick={() => history.goBack()}
              icon="left"
              className="goback"
            >
              返回
            </Button>
            <div className="title">订单明细</div>
          </Card>

          <Card className="content">
            <div>统计日期： {date}</div>
            <List scrollX={true} location={this.props.location} match={this.props.match} />
          </Card>
        </div>
      </Provider>
    );
  }
}
