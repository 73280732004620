import request from "../../libs/request";
import { getUserInfo } from "../../service";
import uuid from "../../libs/uuid";
export function fetchLoginLogs(params) {
  let query = {
    //station_id: params.stationId,
    start_date: params.startDate,
    end_date: params.endDate,
    search_keyword: params.employeeNo || '',
    pageCount: params.pageCount,
    pageIndex: params.pageIndex,
  };

  return getUserInfo().then(user =>
    request("/data/log/searchLoginLog", query)
  )
  .then(res => ({
    ...res,
    data: res.data.map(item => ({
      id: uuid(),
      name: item.user_name,
      loginTime: item.login_time,
      logoutTime: item.logout_time,
      ip: item.ip_address,
      account: item.account,
    }))
  }))
}
