import React, { Component } from "react";
import { Provider, Subscribe, Container } from "unstated";
import { Form, Icon, Input, Button, Row, Col } from "antd";
import { GTcode } from "../../components";
const Field = Form.Item;
class PureForm extends Component {
  onSubmit(e) {
    const { onSubmit, form } = this.props;
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        onSubmit && onSubmit(values);
      }
    });
  }

  onCancel() {
    this.props.onCancel && this.props.onCancel();
  }

  render() {
    const {
      model,
      form: { getFieldDecorator }
    } = this.props;

    return (
      <Form onSubmit={this.onSubmit.bind(this)} className="login-form">
        <div className="title">用户登录</div>
        <Field>
          {getFieldDecorator("nick_name", {
            rules: [{ required: true, message: "账号不能为空" }]
          })(
            <Input
              prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
              placeholder="账号"
            />
          )}
        </Field>
        <Field>
          {getFieldDecorator("psw", {
            rules: [{ required: true, message: "密码不能为空" }]
          })(
            <Input
              prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
              type="password"
              placeholder="密码"
            />
          )}
        </Field>

        <Field>
          {getFieldDecorator("token", {
            rules: [{ required: true, message: "请进行验证" }]
          })(<GTcode />)}
        </Field>
        {getFieldDecorator("captcha", { initialValue: "" })(
          <Input type="hidden" />
        )}
        <Button
          type="primary"
          htmlType="submit"
          className="login-form-button"
          loading={model.state.submitting}
        >
          登录
        </Button>
        {/* <a className="login-form-forgot">忘记密码</a> */}
      </Form>
    );
  }
}

const WrapedPureForm = Form.create()(PureForm);

export default WrapedPureForm;
