import request from "../../libs/request";
import { getUserInfo } from "../../service";
import uuid from "../../libs/uuid";
export function fetchOperationLogs(params) {
  let query = {
    start_date: params.startDate,
    end_date: params.endDate,
    //station_id: params.stationId,
    search_keyword: params.employeeNo,
    pageCount: params.pageCount,
    pageIndex: params.pageIndex,
  };

  return getUserInfo().then(user =>
    request("/data/log/searchOperateLog", query)
    .then(res => ({
      ...res,
      data: res.data.map(item => ({
        id: uuid(),
        note: item.detail_description,
        name: item.user_name,
        content: item.operate_action,
        object: item.operate_target,
        account: item.account,
        time: item.operate_time,
        
      }))
    }))
  );
}
