import React, { Component } from "react";
import { Table, Divider, message } from "antd";
import { Link } from "react-router-dom";
import { Subscribe } from "unstated";
import model from "./model";
import { getSites, getPaymentSetting } from "../../service";
import {
  PAYED_ORDER_STATUS,
  PAYED_ORDER_STATUS_FILTER,
  ORDER_STATUS,
  ORDER_STATUS_FILTER,
  CAR_FEE_TYPE,
  VISITOR_HAS_REFOUND_PERMISSION,
  INVOICE_STATUS_FILTER,
  TOLLER_SHIFT
} from "../../constants";
import auth from "../../auth";
export default class OrderRecordList extends Component {
  constructor() {
    super();
    this.state = {
      trading_channels: [],
      trading_manners: []
    };
  }
  componentDidMount() {
    // getSites()
    //   .then(({ sites, user }) => {
    //     model
    //       .filter({
    //         toll_station_id: sites[0].id,
    //         begin_time: "",
    //         end_time: "",
    //         condition: "",
    //         pageIndex: 1,
    //         channel_id: "",
    //         pay_type_id: "",
    //         pay_status: 0,
    //         pageCount: 10
    //       })
    //       .then(() => {
    //         model
    //           .queryPayedOrders(model.state.filter)
    //           .catch(e => message.error(e.message));
    //       });
    //   })
    //   .catch(e => {
    //     console.log(e);
    //     message.error("获取站点失败");
    //   });

    getPaymentSetting().then(({ trading_channels, trading_manners }) => {
      this.setState({
        trading_channels,
        trading_manners
      });
    });
  }
  onChange({ current, pageSize }, filters, sorter) {
    const { payTypeName, payChannelName, payStatus, orderStatus, fp_qqlsh } = filters;

    model
      .filter({
        pageIndex: model.state.filter.pageIndex !== current ? current : 1,
        pageCount: pageSize,
        ...(payChannelName ? { channel_id: payChannelName[0] || "" } : {}),
        ...(payTypeName ? { pay_type_id: payTypeName[0] || "" } : {}),
        ...(payStatus ? { pay_status: payStatus[0] || 0 } : {}),
        ...(orderStatus ? { order_status: orderStatus[0] || 0 } : {}),
        ...(fp_qqlsh ? { fp_qqlsh: fp_qqlsh[0] || 0 } : {})
      })
      .then(() => {
        model.queryPayedOrders().catch(e => message.error(e.message));
      });
  }
  render() {
    const { scrollX } = this.props;
    const { trading_channels, trading_manners } = this.state;

    const getColumns = () => [
      {
        title: "订单创建时间",
        dataIndex: "createTime",
        key: "createTime"
      },
      {
        title: "车道订单号",
        dataIndex: "feeSystemTradeNo",
        key: "feeSystemTradeNo"
      },
      {
        title: "腾路订单号",
        dataIndex: "orderNo",
        key: "orderNo"
      },
      {
        title: "腾路流水号",
        dataIndex: "flowNo",
        key: "flowNo"
      },
      {
        title: "渠道流水号",
        dataIndex: "otherTrxNo",
        key: "otherTrxNo"
      },
      // {
      //   title: "收费站点",
      //   dataIndex: "outSiteName",
      //   key: "outSiteName"
      // },
      {
        title: "应结订单金额(元)",
        dataIndex: "totalFee",
        key: "totalFee",
        render: v => (
          <span className="money">{v ? (v / 100).toFixed(2) : 0}</span>
        )
      },
      {
        title: "实际支付金额(元)",
        dataIndex: "payFee",
        key: "payFee",
        render: v => (
          <span className="money">{v ? (v / 100).toFixed(2) : 0}</span>
        )
      },
      {
        title: "车牌号",
        dataIndex: "cardNo",
        key: "cardNo"
      },
      {
        title: "车辆收费类型",
        dataIndex: "carFeeType",
        key: "carFeeType",
        render: v => CAR_FEE_TYPE[v]
      },
      {
        title: "支付方式",
        dataIndex: "payTypeName",
        key: "payTypeName",
        filterMultiple: false,
        filters: trading_manners.length >= 2 ? trading_manners : [],
        filteredValue: model.state.filter.pay_type_id
          ? [model.state.filter.pay_type_id]
          : null
      },
      {
        title: "支付渠道",
        dataIndex: "payChannelName",
        key: "payChannelName",
        filterMultiple: false,
        filteredValue: model.state.filter.channel_id
          ? [model.state.filter.channel_id]
          : null,
        filters: trading_channels.length >= 2 ? trading_channels : []
      },
      {
        title: "支付状态",
        dataIndex: "payStatus",
        key: "payStatus",
        render: v => PAYED_ORDER_STATUS[v],
        filterMultiple: false,
        filteredValue: model.state.filter.pay_status
          ? [model.state.filter.pay_status]
          : null,
        filters: PAYED_ORDER_STATUS_FILTER
      },
      {
        title: "订单状态",
        dataIndex: "orderStatus",
        key: "orderStatus",
        render: v => ORDER_STATUS[v],
        filterMultiple: false,
        filteredValue: model.state.filter.order_status
          ? [model.state.filter.order_status]
          : null,
        filters: ORDER_STATUS_FILTER
      },
      {
        title: "开票状态",
        dataIndex: "fp_qqlsh",
        key: "fp_qqlsh",
        render: v => (v ? "已开票" : "未开票"),
        filterMultiple: false,
        filteredValue: model.state.filter.fp_qqlsh
          ? [model.state.filter.fp_qqlsh]
          : null,
        filters: [{ value: 1, text: "已开票" }, { value: 2, text: "未开票" }]
      },
      {
        title: "车道编号",
        dataIndex: "outLaneNo",
        key: "outLaneNo"
      },
      {
        title: "班次",
        dataIndex: "classes_type",
        render: (value, record) => {
          return (record.account_date || "").substr(0,10) + " " + (TOLLER_SHIFT[value] || "")
        }
      },
      {
        title: "操作",
        key: "action",
        fixed: "right",
        width: "auto",
        render: item => (
          <span>
            {auth.hasAuthority(VISITOR_HAS_REFOUND_PERMISSION) &&
            item.canRefound
              ? [
                  <Link
                    key="1"
                    disabled={!item.canRefound}
                    to={{
                      pathname: `/orders/drawback/${item.feeSystemTradeNo}`,
                      state: {
                        modal: true,
                        fields: { ...item },
                        from: this.props.location
                      }
                    }}
                  >
                    退款
                  </Link>,
                  <Divider type="vertical" key="2" />
                ]
              : null}

            <Link
              to={{
                pathname: `/orders/detail/${item.flowNo}`,
                state: {
                  modal: true,
                  from: this.props.location
                }
              }}
              key="3"
            >
              查看
            </Link>
          </span>
        )
      }
    ];
    return (
      <Subscribe to={[model]}>
        {model => (
          <Table
            rowKey="flowNo"
            pagination={{
              ...model.state.pagination,
              showSizeChanger: true,
              showQuickJumper: true,
              showTotal: total => `共${total}条数据`
            }}
            onChange={this.onChange.bind(this)}
            scroll={{ x: !!scrollX ? true : false }}
            className="list"
            columns={getColumns()}
            dataSource={model.state.list}
          />
        )}
      </Subscribe>
    );
  }
}
