import React, { Component } from 'react';
import { Form, Button, Input, Select, Alert } from 'antd';
import { Provider, Subscribe, Container } from 'unstated';
import { BALANCE_ERROR_TYPE } from '../../constants';
const Field = Form.Item;
class PureForm extends Component {

  onSubmit(e) {
    const { onSubmit, form, location: { state: { fields } } } = this.props;
    e.preventDefault();
    onSubmit && onSubmit()
  }

  onCancel() {
    this.props.onCancel && this.props.onCancel()
  }


  render() {
    const { form, location: { state: { fields } } } = this.props;
    const { getFieldDecorator, getFieldValue } = this.props.form;
    return (
      <Form className="modal-form" onSubmit={this.onSubmit.bind(this)}>
        <Field label="处理结果"><span className="text">已处理</span></Field>
        <Field label="操作员"><span className="text">{fields.operater}</span></Field>
        <Field label="处理时间"><span className="text">{fields.handleTime}</span></Field>
        <Field label="差错类型"><span className="text">{BALANCE_ERROR_TYPE[fields.mistakeType]}</span></Field>
        <Field label="备注">
          <span className="text">{fields.remark}</span>
        </Field>

        <div className="modal-form-footer">
          <Button className="modal-form-cancel" onClick={this.onCancel.bind(this)} >取消</Button>
          <Button className="modal-form-submit" type="primary" htmlType="submit" >确定</Button>
        </div>
      </Form>
    )
  }
}

const WrapedPureForm = Form.create()(PureForm);

export default WrapedPureForm;


