import React, { Component } from 'react';
import { Card, Button, Radio } from 'antd';
import { Link } from 'react-router-dom';
import Search from './search';
import List from './list';
import { Provider } from 'unstated';
import "./index.less";
export default class BalanceRecords extends Component {
  render() {
    return (
      <Provider>
        <div className="page-content BalanceRecords">

          <Card className="header">
            <div className="title">对账管理</div>
            <div className="extra">
              <Radio.Group value="1">
                <Radio.Button value="1">对账单</Radio.Button>
                <Radio.Button value="2"><Link to="/balance/Errors">差错账</Link></Radio.Button>
              </Radio.Group>
            </div>
          </Card>

          <Card className="content">
            <Search></Search>
            <List scrollX={true} />
          </Card>



        </div>
      </Provider>
    )
  }
}
