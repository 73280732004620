import React, { Component } from "react";
import { Card, Button, Radio } from "antd";
import { Link } from "react-router-dom";
import Search from "./search";
import List from "./list";
import { Provider } from "unstated";
import "./index.less";
export default class Tollers extends Component {
  render() {
    return (
      <div className="page-content Tollers">
        <Card className="header">
          <div className="title">账号管理</div>
          <div className="extra">
            <Radio.Group value="2">
              <Radio.Button value="1">
                <Link to="/users/records">操作员管理</Link>
              </Radio.Button>
              <Radio.Button value="2">收费员管理</Radio.Button>
            </Radio.Group>
          </div>
        </Card>
        <Provider>
          <Card className="content">
            <Button type="primary" className="add-user">
              <Link
                to={{
                  pathname: `/tollers/add`,
                  state: { modal: true, from: this.props.location }
                }}
              >
                新增账号
              </Link>
            </Button>
            <Search />
            <List scrollX={true} location={this.props.location} />
          </Card>
        </Provider>
      </div>
    );
  }
}
