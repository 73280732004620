import React, { Component } from "react";
import { Table, Divider, message } from "antd";
import { Link } from "react-router-dom";
const { Column, ColumnGroup } = Table;
import { Subscribe } from "unstated";
import model from "./model";
import moment from "moment";
import auth from "../../auth";
import {
  HANDLE_STATUS,
  ERROR_TYPE,
  BALANCE_ERROR_TYPE,
  HANDLE_STATUS_FILTER,
  BALANCE_ERROR_TYPE_FILTER
} from "../../constants";
import { getSites, getPaymentSetting } from "../../service";
export default class BalanceErrorList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sites: [],
      trading_channels: [],
      trading_manners: []
    };
  }
  componentDidMount() {
    auth.getUserInfo().then(user => {
      model
        .filter({
          merchant_id: user.merchant_id + "",
          site_id: "",
          pageIndex: 1,
          pageCount: 10,
          handle_status: "",
          error_type: "",
          keyword: "",
          operator_id: 0,
          channel_id: "",
          pay_type_id: "",
          startTime: moment()
            .subtract(7, "days")
            .startOf("day")
            .format("YYYY-MM-DD HH:mm:ss"),
          endTime: moment()
            .endOf("day")
            .format("YYYY-MM-DD HH:mm:ss")
        })
        .then(() => {
          model.getErrorBalanceRecords().catch(e => message.error(e.message));
        });
    });

    getSites()
      .then(({ sites }) =>
        this.setState({
          sites: sites.map(site => ({ value: site.id, text: site.name }))
        })
      )
      .catch(console.log);

    getPaymentSetting().then(({ trading_channels, trading_manners }) => {
      this.setState({
        trading_channels,
        trading_manners
      });
    });
  }
  onChange({ current, pageSize }, filters, sorter) {
    const { stationName, mistakeType, handlerSts, pay_type_id, channel_id } = filters;
    model
      .filter({
        pageIndex: model.state.filter.pageIndex !== current ? current : 1,
        pageCount: pageSize,
        ...(stationName ? { site_id: stationName[0] || "" } : {}),
        ...(handlerSts ? { handle_status: handlerSts[0] || "" } : {}),
        ...(mistakeType ? { error_type: mistakeType[0] || 0 } : {}),
        ...(pay_type_id ? { pay_type_id: pay_type_id[0] || "" } : {}),
        ...(channel_id ? { channel_id: channel_id[0] || "" } : {})
      })
      .then(() => {
        model.getErrorBalanceRecords().catch(e => message.error(e.message));
      });
  }
  render() {
    const { scrollX } = this.props;
    const { trading_channels, trading_manners } = this.state;
    let site_options = () => {
      if (this.state.sites.length > 1) {
        return {
          filterMultiple: false,
          filteredValue: model.state.filter.site_id
            ? [model.state.filter.site_id + ""]
            : null,
          filters: this.state.sites
        };
      }
      return {};
    };

    let handle_status_options = () => ({
      filterMultiple: false,
      filteredValue: model.state.filter.handle_status
        ? [model.state.filter.handle_status + ""]
        : null,
      filters: HANDLE_STATUS_FILTER
    });
    let error_type_options = () => ({
      filterMultiple: false,
      filteredValue: model.state.filter.error_type
        ? [model.state.filter.error_type + ""]
        : null,
      filters: BALANCE_ERROR_TYPE_FILTER
    });

    let trading_manner_options = () => ({
      filterMultiple: false,
      filteredValue: model.state.filter.pay_type_id
        ? [model.state.filter.pay_type_id + ""]
        : null,
      filters: trading_manners.length >= 2 ? trading_manners : []
    });

    let trading_channel_options = () => ({
      filterMultiple: false,
      filteredValue: model.state.filter.channel_id
        ? [model.state.filter.channel_id + ""]
        : null,
      filters: trading_channels.length >= 2 ? trading_channels : []
    });

    return (
      <Subscribe to={[model]}>
        {model => (
          <Table
            rowKey="id"
            pagination={{
              ...model.state.pagination,
              showSizeChanger: true,
              showQuickJumper: true,
              showTotal: total => `共${total}条数据`
            }}
            onChange={this.onChange.bind(this)}
            className="list"
            scroll={{ x: !!scrollX ? true : false }}
            dataSource={model.state.list}
          >
            <Column
              title="交易日期"
              dataIndex="reconDate"
              key="reconDate"
              render={v => (typeof v === "string" ? v.substr(0, 10) : v)}
            />
            <Column
              title="收费站点"
              dataIndex="stationName"
              key="stationName"
              {...site_options()}
            />

            <Column title="处理时间" dataIndex="handleTime" key="handleTime" />
            <Column title="车牌号" dataIndex="cardNo" key="cardNo" />
            <Column
              title="交易金额(元)"
              dataIndex="payAmount"
              key="payAmount"
              render={v => (
                <span className="money">{v ? (v / 100).toFixed(2) : 0}</span>
              )}
            />
            <Column
              title="手续费(元)"
              dataIndex="payFee"
              key="payFee"
              render={v => (
                <span className="money">{v ? (v / 100).toFixed(2) : 0}</span>
              )}
            />
            <Column
              title="腾路订单号"
              dataIndex="mobileOrderNo"
              key="mobileOrderNo"
            />
            <Column
              title="腾路流水号"
              dataIndex="mobileSerial"
              key="mobileSerial"
            />
            <Column
              title="渠道流水号"
              dataIndex="channelSerial"
              key="channelSerial"
            />
            <Column
              title="差错原因"
              dataIndex="mistakeType"
              key="mistakeType"
              render={v => BALANCE_ERROR_TYPE[v]}
              {...error_type_options()}
            />

            <Column
              title="交易方式"
              dataIndex="pay_type_id"
              key="pay_type_id"
              {...trading_manner_options()}
            />

            <Column
              title="支付渠道"
              dataIndex="channel_id"
              key="channel_id"
              {...trading_channel_options()}
            />

            <Column title="操作员" dataIndex="operater" key="operater" />

            <Column
              title="处理状态"
              dataIndex="handlerSts"
              key="handlerSts"
              render={v => HANDLE_STATUS[v]}
              {...handle_status_options()}
            />
            <Column title="备注" dataIndex="remark" key="remark" />
            <Column
              title="操作"
              key="action"
              fixed="right"
              width="auto"
              render={item =>
                item.handlerSts != 1 ? (
                  auth.canRoute("/balance/handleError") ? (
                    <Link
                      to={{
                        pathname: `/balance/handleError`,
                        state: {
                          modal: true,
                          fields: { ...item },
                          from: this.props.location
                        }
                      }}
                    >
                      处理
                    </Link>
                  ) : null
                ) : (
                  <Link
                    to={{
                      pathname: `/balance/handleResult`,
                      state: {
                        modal: true,
                        fields: { ...item },
                        from: this.props.location
                      }
                    }}
                  >
                    查看
                  </Link>
                )
              }
            />
          </Table>
        )}
      </Subscribe>
    );
  }
}
