import React, { Component } from 'react';
import { Table, message , Divider, Modal} from 'antd';
import { Subscribe } from 'unstated';
import model from './model';
import { Link } from 'react-router-dom';
const { Column } = Table;

export default class UserList extends Component {
  componentDidMount() {
    model.queryTollers()
      .catch(e => message.error(e.message))
  }

  onChange({ current, pageSize }) {
    model.searchTollers({
      pageIndex: current,
      pageCount: pageSize,
    })
      .catch(e => message.error(e.message))
  }

  willRemoveToller(id){
    Modal.confirm({
      title: "提示",
      content: "是否确定删除该收费员？",
      onOk() {
        model
          .removeToller(id)
          .then(() => message.success("删除成功"))
          .catch(e => {
            console.log(e);
            message.error("删除失败");
          });
      },
      onCancel() {}
    });
  }
  
  render() {
    const { scrollX } = this.props;
    return (
      <Subscribe to={[model]}>
        {
          model => (
            <Table
              pagination={{
                ...model.state.pagination,
                showSizeChanger: true,
                showQuickJumper: true,
                showTotal: total => `共${total}条数据`
              }}
              onChange={this.onChange.bind(this)}
              scroll={{ x: !!scrollX ? true : false }}
              rowKey="id"
              className="list"
              dataSource={model.state.list}>
              <Column
                title="姓名"
                dataIndex="name"
                key="name"
              />
              <Column
                title="工号"
                dataIndex="casher_number"
                key="casher_number"
              />
              <Column
                title="手机号"
                dataIndex="phone"
                key="phone"
              />
              <Column
                title="所属站点"
                dataIndex="toll_station_name"
                key="toll_station_name"
              />

              <Column
                title="操作"
                key="action"
                fixed="right"
                width="auto"
                render={(item) => (
                  <span>
                    <Link to={{
                      pathname: `/tollers/edit/${item.id}`,
                      state: { modal: true, fields: { ...item }, from: this.props.location },
                    }}>编辑</Link>
                    <Divider type="vertical" />
                    <a onClick={() => this.willRemoveToller(item)}>删除</a>
                  </span>
                )}
              />

            </Table>
          )
        }
      </Subscribe>
    )
  }
}