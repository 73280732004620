
class App {
  constructor() {
    this.models = {};
    this.pages = {};
    this.modals = {};
    this.routes = {};
  }

  registRoute(url, component) {
    this.routes[url] = component;
  }
  getRoute(url) {
    return this.routes[url]
  }

  registPage(url, component, permission) {
    this.pages[url] = {
      component,
      url,
      permission
    };
  }
  getPage(url) {
    return this.pages[url]
  }

  registModal(url, component, permission) {
    this.modals[url] = {
      component,
      url,
      permission
    };;
  }
  getModal(url) {
    return this.modals[url]
  }


  registModel(name, instance) {
    this.models[name] = instance
  }
  getModel(name) {
    const _m = this.models[name];
    if (!_m) throw `model ${name} not exist`
    return this.models[name]
  }

  onError() {

  }


  getAllRoutes() {
    return {
      ...this.pages,
      ...this.modals
    }
  }

  isRouteMatch(url, routeUri) {
    if (typeof routeUri !== 'string') return false;
    let reg = routeUri.replace(/\:[\w]+/g, '([^\/]*?)');
    reg = new RegExp(`^${reg}$`)
    return reg.test(url);
  }

}


export default new App()

//import { Provider, Subscribe, Container } from 'unstated';


