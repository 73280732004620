import React, { Component } from "react";
import { Table, message } from "antd";
import { Subscribe } from "unstated";
import model from "./model";
const { Column } = Table;

export default class List extends Component {
  onChange({ current, pageSize }) {
    model
      .filterOperationLogs({
        pageIndex: current,
        pageCount: pageSize
      })
      .catch(e => message.error(e.message));
  }

  render() {
    const { scrollX } = this.props;
    return (
      <Subscribe to={[model]}>
        {model => (
          <Table
            pagination={{
              ...model.state.pagination,
              showSizeChanger: true,
              showQuickJumper: true,
              showTotal: total => `共${total}条数据`
            }}
            onChange={this.onChange.bind(this)}
            scroll={{ x: !!scrollX ? true : false }}
            rowKey="id"
            className="list"
            dataSource={model.state.list}
          >
            <Column
              title="姓名"
              dataIndex="name"
              key="name"
              render={v => (typeof v === "string" ? v.substr(0, 10) : "")}
            />

            <Column title="账号" dataIndex="account" key="account" />
            <Column title="操作对象" dataIndex="object" key="object" />
            <Column title="操作内容" dataIndex="content" key="content" />
            <Column title="操作详细" dataIndex="note" key="note" />
            <Column title="操作时间" dataIndex="time" key="time" />
          </Table>
        )}
      </Subscribe>
    );
  }
}
