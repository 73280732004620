import React, { Component } from 'react';
import { Layout, Menu, Breadcrumb, Icon, Card } from 'antd';
const { SubMenu } = Menu;
const { Header, Content, Sider } = Layout;
import styles from './index.less';
import logo from '../../asset/logo.png';
import UserMenu from '../UserMenu';
import { Link } from 'react-router-dom';
import SideMenu from '../SideMenu';
import cdn from '../../libs/cdn';
export default class Page extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
    }
  }

  onCollapse(collapsed) {
    this.setState({ collapsed: !this.state.collapsed });
  }
  render() {
    const { children } = this.props;
    return (
      <Layout className="Page">
        <Header className="header">
          <img className="logo" src={cdn('logo.png')} />
          <span className="title">智能化收费平台</span>
          <UserMenu />
        </Header>
        <Layout>
          <Sider
            trigger={null}
            collapsible
            collapsed={this.state.collapsed}
            // onCollapse={this.onCollapse.bind(this)}
            collapsedWidth={52}
            width={186}>
            <Icon
              className="trigger"
              type={this.state.collapsed ? 'menu-unfold' : 'menu-fold'}
              onClick={this.onCollapse.bind(this)}
            />
            {/* <Menu
              theme="dark"
              mode="inline"
              defaultSelectedKeys={['1']}
              defaultOpenKeys={['sub2']}
              style={{ height: '100%', borderRight: 0 }}
            >

              <SubMenu key="sub2" title={<span><Icon type="profile" />交易数据</span>}>
                <Menu.Item key="5"><Link to="/orders/records">订单数据</Link></Menu.Item>
                <Menu.Item key="6"><Link to="/balance/records">对账管理</Link></Menu.Item>

              </SubMenu>
              <SubMenu key="sub3" title={<span><Icon type="setting" />系统管理</span>}>
                <Menu.Item key="9"><Link to="/users/records">账号管理</Link></Menu.Item>

              </SubMenu>
            </Menu> */}
            <SideMenu location={this.props.location}/>
          </Sider>
          <Layout style={{ padding: '0 0 24px 0' }}>
            <Content>
              {children}
            </Content>
          </Layout>
        </Layout>
      </Layout>
    )
  }
}