import React, { Component } from "react";
import { Select, message } from "antd";
import auth from "../../auth";
import { getSites } from "../../service";
export default class SiteSelector extends Component {
  constructor(props) {
    super();
    this.state = {
      user: {},
      sites: [],
      value: props.value
    };
  }
  componentWillReceiveProps(nextProps) {
    if ("value" in nextProps && nextProps.value !== undefined) {
      const value = nextProps.value;
      this.setState({ value });
    }
  }
  componentDidMount() {
    getSites(!!this.props.showAllSites)
      .then(({ sites }) => {
        this.setState({
          sites
        });
        if (!this.props.notSelectFirst || sites.length === 1) {
          let value = this.props.mode
            ? [(sites[0] || {}).id]
            : (sites[0] || {}).id;
          this.setState({
            value
          });
          this.props.onChange && this.props.onChange(value);
        }
      })
      .catch(e => message.error(e.message));
  }
  render() {
    let rest = {
      placeholder: this.props.placeholder || "请选择"
    };

    return (
      <span>
        {this.state.sites && this.state.sites.length ? (
          <Select
            showArrow={this.state.sites.length > 1}
            value={this.state.value}
            onChange={this.props.onChange}
            mode={this.props.mode}
            {...rest}
          >
            {this.state.sites.map(s => (
              <Select.Option key={s.id} value={s.id}>
                {s.name}
              </Select.Option>
            ))}
          </Select>
        ) : null}
      </span>
    );
  }
}
