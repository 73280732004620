import React, { Component } from "react";
import { Card, Button, Radio } from "antd";
import { Link } from "react-router-dom";
import Search from "./search";
import List from "./list";
import { Provider, Subscribe } from "unstated";
import "./index.less";
import model from "./model";
export default class ChargeReports extends Component {
  constructor(props) {
    const {
      match: {
        params: { type }
      }
    } = props;
    super(props);
  }

  componentDidMount() {
    //model.resetChargeReportsQueryParamsAndFetch();
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.match.params.type &&
      nextProps.match.params.type !== this.props.match.params.type
    ) {
      this.onReportTypeChange(nextProps.match.params.type);
    }
  }

  onReportTypeChange(type) {
    model.resetChargeReportsQueryParamsAndFetch(type);
  }

  render() {
    const REPORT_TABLE_NAME = {
      batch: "班次报表",
      day: "日报表",
      month: "月度报表",
      year: "年度报表"
    };
    return (
      <div className="page-content ChargeReports">
        <Provider>
          <Card className="header">
            <div className="title">
              {REPORT_TABLE_NAME[this.props.match.params.type]}
            </div>
          </Card>

          <Card className="content">
            <Search match={this.props.match} />
            <List
              scrollX={true}
              location={this.props.location}
              match={this.props.match}
            />
          </Card>
        </Provider>
      </div>
    );
  }
}
