import React, { Component } from 'react';
import { Form, Button, Input, Select, message } from 'antd';
const Field = Form.Item;
import model from './model';
import moment from 'moment';
import { DateTimeRangePicker } from '../../components';
class BalanceDayRecordSearch extends Component {
  onSubmit(e) {
    const { form } = this.props;
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        let [startTime, endTime] = values.date;
        let filter = {
          pageIndex: 1,
        };
        startTime ? filter.startTime = moment(startTime).format('YYYY-MM-DD HH:mm:ss') : null;
        endTime ? filter.endTime = moment(endTime).format('YYYY-MM-DD HH:mm:ss') : null;
        values.keyword && values.keyword.trim() ? filter.keyword = values.keyword : null;
        model.filter(filter).then(() => {
          model.getBlanceRecodesDetailOfDay()
            .catch(e => message.error(e.message))
        })
      }
    });

  }

  onReset() {
    const { resetFields, getFieldsValue } = this.props.form;
    resetFields();
    const fields = getFieldsValue();
    model.filter({
      startTime: fields.date[0].format('YYYY-MM-DD HH:mm:ss'),
      endTime: fields.date[1].format('YYYY-MM-DD HH:mm:ss'),
      trading_type: "",
      trading_manner: "",
      trading_status: "4" /*TRADING_SUCCESS*/,
      trading_channel: "",
      error_type: "",
      site_id: "",
      keyword: "",
      pageIndex: 1,
      pageCount: 10,
    }).then(() => {
      model.getBlanceRecodesDetailOfDay()
        .catch(e => message.error(e.message))
    })
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { location: { state } } = this.props;
    const { site_id, date } = state.fields;
    const date_options = {
      initialValue: [moment(date).startOf('day'), moment(date).endOf('day')]
    }
    return (
      <Form layout="inline" className="search" onSubmit={this.onSubmit.bind(this)}>

        <Field label="交易时间">
          {getFieldDecorator('date', date_options)(
            <DateTimeRangePicker
              disabledDate={current => {

                if (moment(current).format('YYYY-MM-DD') !== moment(date).format('YYYY-MM-DD')) {
                  return true;
                }
                return false;
              }}
            />)}
        </Field>

        <Field label="关键词">{getFieldDecorator('keyword')(<Input placeholder="车牌号/订单号/流水号/收费员编号" />)}</Field>
        <Button type="primary" htmlType="submit">查询</Button>
        <Button htmlType="button" onClick={() => this.onReset()}>重置</Button>
        {
          model.hasExportAuthority() ? <Button className="export" onClick={() => model.exportBlanceRecodesDetailOfDay({ pageIndex: -1 })}>导出数据</Button> : null
        }

      </Form>
    )
  }
}

export default Form.create()(BalanceDayRecordSearch)