import React, { Component } from 'react';
import { Form, Button, Input, Select, Modal } from 'antd';

const Field = Form.Item;
class PureForm extends Component {

  onSubmit(e) {
    const { onSubmit, form } = this.props;
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        onSubmit && onSubmit(values)
      }
    });

  }

  onCancel() {
    this.props.onCancel && this.props.onCancel()
  }
  componentDidMount() {
    const { model, form } = this.props;
    
    model.getDetail({ })
      .then(() => {
       
        if (Object.keys(model.state.fields).length) {

          form.setFieldsValue(model.state.fields)
        }
      })


  }
  componentWillUnmount() {

  }
  render() {
    const { model, form } = this.props;
    const old_psw_options = {
      rules: [{
        required: true,
        message: '请输入原密码',
      },
      
      
    ],
    }

    const new_psw_options = {
      rules: [{
        required: true,
        message: '请输入新密码',
      },
      {
        max: 16,
        min: 8,
        message: '密码长度为8-16位',
      },
      {
        pattern: /^[\da-zA-Z]+$/,
        message: '密码只能包含数字和字母'
      },
    ],
    }

    const new_psw_confirm_options = {
      rules: [{
        required: true,
        message: '请确认新密码',
      },
     
      {
        validator(rule,value,callback){
          const new_psw = form.getFieldValue('new_psw');
          if (new_psw === value){
            callback()
          }else{
            callback('新密码不一致')
          }
        }
      },
    ],
    }
    return (
      <Form className="modal-form" onSubmit={this.onSubmit.bind(this)}>
        <Field label="原密码">{form.getFieldDecorator('old_psw',old_psw_options)(<Input type="password"/>)}</Field>
        <Field label="新密码">{form.getFieldDecorator('new_psw',new_psw_options)(<Input type="password"/>)}</Field>
        <Field label="确认密码">{form.getFieldDecorator('new_psw_confirm',new_psw_confirm_options)(<Input type="password"/>)}</Field>
        
        <div className="modal-form-footer">
          <Button className="modal-form-cancel" onClick={this.onCancel.bind(this)} disabled={model.state.submitting}>取消</Button>
          <Button className="modal-form-submit" type="primary" htmlType="submit" loading={model.state.submitting}>确定</Button>
        </div>
      </Form>
    )
  }
}

const WrapedPureForm = Form.create()(PureForm);

export default WrapedPureForm;


