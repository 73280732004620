import React, { Component } from 'react';
import { Card, Button, Radio } from 'antd';
import { Link } from 'react-router-dom';
import Search from './search';
import List from './list';
import { Provider } from 'unstated';
import './index.less';
export default class LoginLogs extends Component {

  render() {
    return (

      <div className="page-content LoginLogs">

        <Card className="header">
          <div className="title">日志管理</div>
          <div className="extra">
            <Radio.Group value="1">
              <Radio.Button value="1">登录日志</Radio.Button>
              <Radio.Button value="2"><Link to="/logs/operation">操作日志</Link></Radio.Button>
            </Radio.Group>
          </div>
        </Card>
        <Provider>
          <Card className="content">
            <Search location={this.props.location}></Search>
            <List scrollX={true} location={this.props.location} />
          </Card>
        </Provider>
      </div>


    )
  }
}
