const schema = {
  name: {
    value: '',
    name: '姓名',
    rules: [
      {
        required: true,
        message: '姓名不能为空'
      },
      {
        max: 30,
        message: '姓名不能超过30个字符'
      },
      {
        pattern: /^[\u4e00-\u9fa5a-zA-Z0-9]+$/,
        message: '姓名格式只能包含中文、字母和数字'
      }
    ]
  },
  casher_number: {
    value: '',
    name: '工号',
    rules: [
      {
        required: true,
        message: '工号不能为空'
      },
      {
        pattern: /^\d+$/,
        message: '工号只能是数字'
      },
      {
        max: 50,
        message: '工号长度不能超过50个字符'
      },
    ]
  },
  phone: {
    value: '',
    name: '手机号',
    rules: [
      {
        pattern: /^1\d{10}$/,
        message: '手机号格式不正确'
      },
    ]
  },
  toll_station_id: {
    value: '',
    name: '所属站点',
    rules: [
      {
        required: true,
        message: '请选择所属站点'
      },
    ]
  },
}

export default schema;