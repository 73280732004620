import request from '../../libs/request';
import exportFile from '../../libs/exportFile';
export function getBalanceMonthly(params){
  return request('/data/trade/queryMonthBill', {
    startDate: params.startTime,
    endDate: params.endTime,
    merchantId: params.merchantId,
    pageCount: params.pageCount,
    pageIndex: params.pageIndex,
  })
}
export function exportBalanceMonthly(params){
  return exportFile('/data/trade/exportTotalOfCS',{
    startDate: params.startTime,
    endDate: params.endTime,
    merchantId: params.merchantId,
    pageCount: params.pageCount,
    pageIndex: params.pageIndex,
  })
}

	