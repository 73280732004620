import { Container } from 'unstated';
import { login } from './service';
class LoginForm extends Container {
  constructor(props) {
    super(props);
    this.initialState = {
      submitting: false,
      fields: {},
    }
    this.state = {
      ...this.initialState
    }

  }

  submit(values) {
    this.setState({ submitting: true })

    return login({...values, phone: ''})
      .then(res => {
        this.setState({ submitting: false })
        return res;
      })
      .catch(e => {
        this.setState({ submitting: false })
        throw e;
      });
  };

  clearForm() {
    this.setState({
      ...this.initialState
    })
  };


}

export default new LoginForm();