
import handleError from './handleError';
const defaultParams = {
  headers: {
    'Accept': 'application/json',
    'content-type': 'application/json; charset=utf-8'
  },
  method: 'POST',
  credentials : 'same-origin'
};
export default function request(url, body, params = { method: 'POST' }) {
  let opts = { ...defaultParams, ...params };
  if (body){
    opts.body = JSON.stringify(body);
  }
  return fetch(url, opts)
    .then(res => res.json())
    .then(res => {
      if (res.status !== 0) {
        let error = new Error(res.message);
        error.code = res.status;
        throw error
      }
      else {
        return res
      }
    })
    .then(res => {
      const pageination = res.pageination || res.pageinationInfo || res.pagination
      if (pageination){
        res.pagination = {
          pageSize: pageination.listRows,
          total: pageination.totalRows,
          current: pageination.currentPage
        }
      }
      return res;
    })
    .catch(handleError)
}