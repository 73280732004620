import React, { Component } from "react";
import { Card, message, Button } from "antd";
import List from "./list";
import { Provider, Subscribe } from "unstated";
import "./index.less";
import model from "./model";
import { TOLLER_SHIFT } from "../../constants";
import moment from "moment";
export default class ChargeReportsDetail extends Component {
  constructor(props) {
    const {
      match: {
        params: { type }
      }
    } = props;
    super(props);
    model.setState({
      reportType: type
    });
  }
  componentDidMount() {
    const {
      match: { params }
    } = this.props;
    model.queryChargeReportsDetail(params).catch(e => message.error(e.message));
  }
  PrintElem() {
    const {
      match: { params }
    } = this.props;
    window.print();
    model.writePrintLog(params);
  }
  render() {
    const { history } = this.props;
    const title = this.props.location.state
      ? this.props.location.state.title
      : "";
    return (
      <div className="page-content ChargeReportsDetail">
        <Card className="header">
          <Button
            onClick={() => history.goBack()}
            icon="left"
            className="goback"
          >
            返回
          </Button>
          <div className="title">{title}</div>
        </Card>
        <Provider>
          <Card className="content">
            <div className="Report-title">
              {title}
              {model.hasPrintAuthority() ? (
                <Button
                  type="primary"
                  onClick={() => this.PrintElem(".ant-card-body")}
                >
                  打印
                </Button>
              ) : null}
            </div>
            <Subscribe to={[model]}>
              {model => (
                <div className="Report-desc">
                  {model.state.reportType === "month" ? (
                    <span>
                      统计月份： {this.props.match.params.date.substr(0, 7)}
                    </span>
                  ) : (
                    <span>
                      统计日期： {this.props.match.params.date.substr(0, 10)}
                    </span>
                  )}

                  {+this.props.match.params.id ? (
                    <span style={{ paddingLeft: "6em" }}>
                      班次： {TOLLER_SHIFT[this.props.match.params.id]}
                    </span>
                  ) : null}
                </div>
              )}
            </Subscribe>

            <List
              scrollX={true}
              location={this.props.location}
              match={this.props.match}
            />
            <div className="Report-footer">
              <span>制表：</span>
              <span>清帐：</span>
              <span>
                打印日期：
                {moment().format("YYYY-MM-DD")}
              </span>
            </div>
            {/* <div className="Report-remark">注： 收入=入账金额-退款金额</div> */}
          </Card>
        </Provider>
      </div>
    );
  }
}
