import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Router from './router';
import auth from './auth';
import { BrowserRouter } from 'react-router-dom'
import { LocaleProvider } from 'antd';
import zhCN from 'antd/lib/locale-provider/zh_CN';
import moment from 'moment';
import 'moment/locale/zh-cn';
moment.locale('zh-cn');

class Index extends Component {
  
  render() {
    return (
      <LocaleProvider locale={zhCN}>
        <BrowserRouter>
        <Router />
        </BrowserRouter>
      </LocaleProvider>
    )
  }
}


auth.getUserInfo()
.then(user => {
  ReactDOM.render(
    <Index />
    , document.getElementById('mountNode'));
  
}).catch(err => {
  console.log(err);
  ReactDOM.render(
    <Index />
    , document.getElementById('mountNode'));
})


