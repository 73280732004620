import React, { Component } from 'react';
import { Table, Divider, message } from 'antd';
import { Link } from 'react-router-dom';
import { Subscribe } from 'unstated';
import model from './model';
import { getSites } from '../../service';
import { REFOUND_STATUS } from '../../constants';
export default class NormalOrderList extends Component {
  componentDidMount() {

    // getSites()
    //   .then(({ sites, user }) => {
    //     model.filter({
    //       toll_station_id: sites[0].id,
    //       begin_time: '',
    //       end_time: '',
    //       condition: '',
    //       pageIndex: 1,
    //       pageCount: 10,
    //     }).then(() => {
    //       model.queryRefoundOrders(model.state.filter)
    //         .catch(e => message.error(e.message))
    //     })
    //   })
    //   .catch(e => {
    //     console.log(e);
    //     message.error('获取站点失败');
    //   })


  }
  onChange({ current, pageSize }, filters, sorter) {
    model.filter({
      pageIndex: current,
      pageCount: pageSize,
    }).then(() => {
      model.queryRefoundOrders()
        .catch(e => message.error(e.message))
    })
  }
  render() {
    const { scrollX } = this.props;
    const columns = [
      {
        title: '订单创建时间',
        dataIndex: 'orderCreateTime',
        key: 'orderCreateTime',
      },
      {
        title: '退款发起时间',
        dataIndex: 'createTime',
        key: 'createTime',
      },
      // {
      //   title: '收费站点',
      //   dataIndex: 'outSiteName',
      //   key: 'outSiteName',
      // },
      {
        title: '车牌号',
        dataIndex: 'cardNo',
        key: 'cardNo',
      },
      {
        title: '腾路订单号',
        dataIndex: 'orderNo',
        key: 'orderNo',
      },
      {
        title: '腾路退款单号',
        dataIndex: 'refundTrxNo',
        key: 'refundTrxNo',
      },
      {
        title: '渠道退款单号',
        dataIndex: 'otherRefundTrxNo',
        key: 'otherRefundTrxNo',
      },
      {
        title: '退款金额(元)',
        dataIndex: 'refundAmount',
        key: 'refundAmount',
        render: v => <span className="money">{v ? (v / 100).toFixed(2) : 0}</span>
      },
      {
        title: '退款状态',
        dataIndex: 'refundStatus',
        key: 'refundStatus',
        render: v => REFOUND_STATUS[v]
      },
      {
        title: '退款原因',
        dataIndex: 'refundReason',
        key: 'refundReason',
      },
      {
        title: '操作员',
        dataIndex: 'createrName',
        key: 'createrName',
      },
      {
        title: '操作',
        key: 'action',
        fixed: 'right',
        width: 'auto',
        render: (item) => (
          <span>
            <Link to={{
              pathname: `/orders/info/${item.id}`,
              state: { modal: true, fields: { ...item }, from: this.props.location },
            }}>查看原始订单</Link>
          </span>
        )

      }];
    return (
      <Subscribe to={[model]}>
        {
          model => (
            <Table
              rowKey="flowNo"
              pagination={{
                ...model.state.pagination,
                showSizeChanger: true,
                showQuickJumper: true,
                showTotal: total => `共${total}条数据`
              }}
              onChange={this.onChange.bind(this)}
              scroll={{ x: !!scrollX ? true : false }}
              className="list"
              columns={columns}
              dataSource={model.state.list} />
          )
        }

      </Subscribe>
    )
  }
}