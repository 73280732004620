import React, { Component } from 'react';
import { Modal , message} from 'antd';
import { Provider, Subscribe } from 'unstated';
import model from './model.js';
import Form from './form.js';
export default class BalanceErrorHandling extends Component {

  handleCancel(e) {
    const { match, history } = this.props;
    history.goBack();
  }

  componentWillUnmount() {

    model.clearForm && model.clearForm();
  }
  render() {
    return (
      <Provider>
        <Subscribe to={[model]}>
          {model => (
            <Modal
              closable={model.state.submitting ? false : true}
              confirmLoading={model.state.submitting}
              maskClosable={false}
              title="差错账处理记录"
              visible={true}
              onOk={this.handleCancel.bind(this)}
              onCancel={this.handleCancel.bind(this)}
              footer={null}
            >
              <Form model={model} onCancel={this.handleCancel.bind(this)} onSubmit={this.handleCancel.bind(this)} location={this.props.location}/>
            </Modal>)
          }
        </Subscribe>
      </Provider>
    )
  }
}